export class Widget360 {
    constructor(id360, images, pause, prev, next) {
        if (this.threesixty != null) {
            this.threesixty.destroy();
        }

        this.pause = pause;
        const imagesJson = JSON.parse(images);

        Promise.all(
            imagesJson.map((src) => {
                return new Promise((resolve, reject) => {
                    const image = new Image();

                    image.onload = resolve;
                    image.onerror = resolve;

                    image.style.display = 'none';
                    image.src = src;

                    // Here we append image to the document body
                    document.body.appendChild(image);
                });
            })
        ).then(() => {
            let width, height;
            if ($(window).width() < 700) {
                width = $(window).width() * 0.7;
                height = $(window).width() * 0.7;
            } else {
                width = $(window).width() * 0.2;
                height = $(window).width() * 0.2;
            }

            this.threesixty = new ThreeSixty(document.getElementById(id360), {
                image: imagesJson,
                width: width,
                height: height,
                speed: 100,
                inverted: true,
                swipeTolerance: 1,
                dragTolerance: 1,
                prev: document.getElementById(prev),
                next: document.getElementById(next),
            });
            this.threesixty.goto(0);
            this.threesixty.play();
            this.updatePauseDisplay();
        });

        $(`#${pause}`).on('click', this.playPause.bind(this));
        $('body').on('click touchmove', this.updatePauseDisplay.bind(this));
    }
    playPause() {
        if (this.threesixty.looping) {
            this.threesixty.stop();
        } else {
            this.threesixty.play();
        }

        this.updatePauseDisplay();
    }

    updatePauseDisplay() {
        if (this.threesixty == null) return;
        if (this.threesixty.looping) {
            $(`#${this.pause}`).removeClass('fa-play').addClass('fa-pause');
        } else {
            $(`#${this.pause}`).addClass('fa-play').removeClass('fa-pause');
        }
    }
    destroy() {
        this.threesixty.destroy();
    }
}

export class carousel {
    constructor(slides, id360, output) {
        this.carousel = slides.children();

        this.currentSlide =
            this.carousel.eq(0).attr('data-360-images') != undefined || this.carousel.eq(0).attr('data-asset-url') != undefined ? 1 : 0;
        slides.children().on('click', this.goToSlide.bind(this));
        this.showSlide(this.currentSlide);
        this.id360 = id360;
        this.idCarousel = slides.attr('id');
        this.output = output;
    }
    goToSlide(event) {
        if (event.currentTarget.getAttribute('carousel-id') == undefined) return;
        this.carousel.removeClass('active');
        if (this.widget360 != null) {
            this.widget360.destroy();
        }
        this.currentSlide = event.currentTarget.getAttribute('carousel-id');
        this.showSlide(this.currentSlide);
    }
    showSlide(index) {
        this.carousel.eq(index).addClass('active');
        let clone;
        if (this.carousel.eq(index).attr('data-360-images') != undefined) {
            clone =
                `<div class="wrapper">\n` +
                `                                        <div><div class="threesixty" id="${this.id360}"></div></div>\n` +
                `                                        <div class="pdp_media_pager pmp_gutter">\n` +
                `                                            <ul style="\n` +
                `                        font-size: 20px;\n` +
                `                        color: #A6A7A9;\n` +
                `                    ">\n` +
                `                                                <li id="${this.idCarousel}-prev" class="fa fa-reply"></li>\n` +
                `                                                <li id="${this.idCarousel}-next" class="fa fa-share"></li>\n` +
                `                                                <li id="${this.idCarousel}-360pause" class="fa fa-pause" style="margin-bottom: 10px;"></li>\n` +
                `                                            </ul>\n` +
                `                                        </div>\n` +
                `                                    </div>`;
            $(this.output).html(clone);

            const images = this.carousel.eq(index).attr('data-360-images');

            this.widget360 = new Widget360(
                this.id360,
                images,
                `${this.idCarousel}-360pause`,
                `${this.idCarousel}-prev`,
                `${this.idCarousel}-next`
            );
        } else if (this.carousel.eq(index).attr('asset-url') != undefined) {
            clone = `<iframe class="youtube"  src="${this.carousel.eq(index).attr('asset-url')}"> </iframe>`;
            $(this.output).html(clone);
        } else {
            clone = this.carousel.eq(index).clone().children().removeClass('mao-thumbImage-slider');
            $(this.output).html(clone);
        }
    }
}
