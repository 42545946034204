/**
 * MAO CustomerService
 */

export var MAO_CustomerService = (function () {
    return {
        init: function () {
            if ($('.js__customerservice').length > 0) {
                var url = window.location.href;
                var item = url.substr(url.indexOf('#'));
                if (url.indexOf('#') !== -1) {
                    $(item).collapse('show');
                }
            }

            $('.contact_js').on('click', null, function () {
                MAO_CustomerService.contact();
            });
        },

        contact: function () {
            $.ajax({
                type: 'POST',
                url: route.contact,
                data: $('.mao-contact-form').serialize(),
                dataType: 'json',

                // show form request validation failures (422)
                error: function (data) {
                    var messages = data.responseJSON;

                    if (jQuery.type(messages.messages) === 'object') {
                        messages = messages.messages;
                    }

                    //clear old errors
                    var field = $('[data-fieldname]');
                    field.removeClass('error');
                    field.find('.mao-form--label').removeClass('error');
                    field.find('#form-error').empty().removeClass('has-error');
                    field.find('.form-group').removeClass('has-error');
                    field.find('select.mao-select').removeClass('has-error');

                    if (jQuery.type(messages) === 'object' && Object.keys(messages).length > 0) {
                        $.each(messages, function (key, value) {
                            $("[data-fieldname='" + key + "'] .mao-form--label")
                                .first()
                                .addClass('error');
                            $("[data-fieldname='" + key + "']")
                                .find('#form-error')
                                .first()
                                //.html('<span>'+value+'</span>')
                                .addClass('has-error');
                            $("[data-fieldname='" + key + "']")
                                .find('.form-group')
                                .first()
                                .removeClass('has-success')
                                .addClass('has-error');
                            $("[data-fieldname='" + key + "']")
                                .find('select.mao-select')
                                .first()
                                .addClass('has-error');
                        });
                    }
                },

                // show http success message (200)
                success: function (data) {
                    //clear old errors
                    var field = $('[data-fieldname]');
                    field.removeClass('error');
                    field.find('.mao-form--label').removeClass('error').removeClass('has-success');
                    field.find('#form-error').empty().removeClass('has-error').removeClass('has-success');
                    field.find('.form-group').removeClass('has-error').removeClass('has-success');
                    field.find('select.mao-select').removeClass('has-error').removeClass('has-success');

                    field.find("[name='message']").val('');

                    var notification = $('.notification-contact');
                    notification.removeClass('mao-notification warn').addClass('mao-notification ticked info');
                    notification.html(data.messages[0]);
                },
            });
        },
    };
})();
