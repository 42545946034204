/**
 * MAO Sort
 */

export var MAO_Sort = (function () {
    return {
        init: function () {},

        applySort: function (sortkey) {
            if (sortkey.length > 0) {
                var pathname = window.location.pathname;
                var params = '?sort=' + sortkey;
                let page;
                if ((page = MAO_Sort.getParameterByName('page')) !== null);
                {
                    params = params + '&page=' + page;
                }
                window.location = pathname + params;
            }
        },

        getParameterByName: function (name) {
            var url = window.location.href;
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },
    };
})();
