/**
 * MAO Account
 */

export var MAO_Account = (function () {
    return {
        init: function () {
            $('.mao-product-fragment-sku').on('click', '.whishList-remove', function (e) {
                e.preventDefault();

                var item = $(this).closest('.mao-product-fragment__txt');
                var localBrandId = item.data('local_brand_id');
                var catalogNr = item.data('catalog_nr');
                MAO_Account.deleteItem(localBrandId, catalogNr);
            });

            var count = $('.mao-product-fragment-select input:checkbox:checked').length;
            $('.orderAccount-toolbarItem-add').prop('disabled', count == 0);

            $('.mao-product-fragment-select').on('change', function () {
                var count = $('.mao-product-fragment-select input:checkbox:checked').length;
                $('#wishListSelected').html(count);
                $('.orderAccount-toolbarItem-add').prop('disabled', count == 0);
            });

            $('.js__wishListSelectedAddToCart').on('click', function () {
                var selectedWishList = $('.mao-product-fragment__txt input:checkbox:checked')
                    .map(function () {
                        return $(this).closest('.mao-product-fragment');
                    })
                    .get();
                MAO_Shopping_Cart.add(selectedWishList);
            });

            $('.js__wishListItemAddToCart').on('click', function () {
                var productFragments = {0: $(this).closest('.mao-product-fragment')};
                MAO_Shopping_Cart.add(productFragments);
            });
        },
        deleteItem: function (localBrandId, catalogNr) {
            $.ajax({
                type: 'delete',
                url: route.wishlist.destroy,
                data: {
                    localBrandId: localBrandId,
                    catalogNr: catalogNr,
                }, //dataString,
                dataType: 'json',

                // show http success message (200)
                success: function (data) {
                    if (data.status == 'success') {
                        MAO_Account.updateWishList(data.message);
                    }
                },
            });
        },
        addItem: function (productFragment) {
            var $this = $(productFragment);
            var product = $this.find('div#product');
            var item = {
                localBrandId: product.data('local_brand_id'),
                catalogNr: product.data('catalog_nr'),
            };

            var alertBox = true;
            $.ajax({
                type: 'POST',
                url: route.wishlist.store,
                data: item,
                dataType: 'json',

                // show http success message (200)
                success: function (data) {
                    if (data['redirect']) {
                        window.location.replace(data['redirect']);
                    }
                    MAO_Account.updateWishList(data.message);
                },
                complete: function (data) {
                    if (alertBox == true) {
                        var _el = $('.js__wishlist-trigger').find('.mao-tooltip');
                        $(_el).addClass('shown');
                        setTimeout(function () {
                            $(_el).removeClass('shown');
                        }, 2000);
                    }
                },
            });
        },
        addToWishList: function (localBrandId, catalogNr) {
            $.ajax({
                type: 'POST',
                url: route.wishlist.store,
                data: {
                    localBrandId: localBrandId,
                    catalogNr: catalogNr,
                },
                dataType: 'json',

                // show http success message (200)
                success: function (data) {
                    if (data['redirect']) {
                        window.location.replace(data['redirect']);
                    }
                    MAO_Account.updateWishList(data.message);
                },
                complete: function (data) {
                    if (alertBox == true) {
                        var _el = $('.js__wishlist-trigger').find('.mao-tooltip');
                        $(_el).addClass('shown');
                        setTimeout(function () {
                            $(_el).removeClass('shown');
                        }, 2000);
                    }
                },
            });
        },
        updateWishList: function (wishList) {
            $('.myAccountWishListItems').html(wishList['myAccountWishListItems']);
            MAO_Account.updateMiniWishList(wishList);
            MAO_Account.init();
        },
        updateMiniWishList: function (wishList) {
            $('#miniWishListContent').html(wishList['miniWishListContent']);
            MAO_HEADER.init();
        },
    };
})();
