import {MAO_CAR} from './car.js';

/**
 * MAO Main Module
 */
// import {MAO_NAVIGATION} from "./navigation.js";

export var MAO_MAIN = (function () {
    var menu_loaded = false;
    return {
        getAmountSlides: function () {
            var spv;
            var _w = window.innerWidth;
            if (_w < 768) {
                spv = 2;
            } else if (_w < 1024) {
                spv = 4;
            } else {
                spv = 6;
            }
            return spv;
        },

        init: function () {
            this.initTabs();
            this.toggle_catalog_collapse();
            MAO_NAVIGATION.init();
            MAO_HEADER.init();
            MAO_MAIN.initCarComponent();
            MAO_MAIN.closeCookieBar();
            MAO_Shopping_Cart.init();
            MAO_Auth.init();

            MAO_HOMEPAGE.init();
            MAO_USER.init();
            MAO_Account.init();
            MAO_Checkout.init();
            MAO_CustomerService.init();
            MAO_Payment.init();
            MAO_Overview.init();
            MAO_PDP.init();
            MAO_Sort.init();
            ONDERDEELMERK_OVERVIEW.init();
            MAO_Facet_Search.init();
            MAO_LISTER.init();
            MAO_TOGGLE_MORE_LESS.init();
            MAO_OrderReturn.init();
            MAO_CAR.init();

            $(function () {
                $('html').removeClass('loading');
            });

            // generate a token we can use for every ajax request
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                },
            });

            $(function () {
                $('[data-toggle="popover"]').popover();
            });

            $(document).on('click', '.mao-more-less a', function (e) {
                $(this).find('span').toggle();
                $(this).find('em').toggle();
            });

            if ($('.c-car-chosen-row__block-logo').length > 0) {
                $('div.mao-top-5').addClass('correct-margin');
            } else {
                $('div.mao-top-5').removeClass('correct-margin');
            }

            $(document).one('click', '.js-load-part-menu', function (e) {
                if (menu_loaded) {
                    return;
                }

                let $sender = $(this);

                $.getJSON($(this).data('location'), function (data) {
                    menu_loaded = true;
                    $('#mm-desktop').html(data.desktop);
                    $('#mm-small').replaceWith(data.small);

                    if ($sender.hasClass('is-active')) {
                        var target = $sender.data('target');

                        $('[data-target=' + target + ']').addClass('is-active');
                        $(target).addClass('is-visible');
                    }
                });
            });

            $(document).on('click', '.js-change-visibility', function (e) {
                var $this = $(this);
                var $group = $this.data('group');
                var $target = $($this.data('target'));
                var visible = $target.hasClass('is-visible') && $this.data('toggle');

                if ($group) {
                    $('[data-group=' + $group + ']').each(function () {
                        var $sender = $(this);

                        $($sender.data('target')).removeClass('is-visible');
                        $sender.removeClass('is-active');
                    });
                }

                if (!visible) {
                    $target.addClass('is-visible');
                    $this.addClass('is-active');
                }
            });

            $(document).on('click', '.vue-add-shopping-item', function () {
                const item = $(this).data('object');

                window.store.addItem(item, item.orderQuantity);
                window.modal.product = item;
                window.modal.showModal = true;

                return false;
            });

            $(document).on('click', '.js-hide-on-click', function (e) {
                var $this = $(this);
                var $group = $this.data('group');

                if ($group) {
                    $('[data-group=' + $group + ']').each(function () {
                        var $sender = $(this);

                        $($sender.data('target')).removeClass('is-visible');
                        $sender.removeClass('is-active');
                    });
                }
            });

            $(document).on('click', '.js-hide-on-leave', function (e) {
                e.stopPropagation();
            });

            $(document).on('click', 'body', function (e) {
                let $items = $('.js-hide-on-leave');

                $items.each(function () {
                    var $this = $(this);
                    var $group = $this.data('group');

                    if ($group) {
                        $('[data-group=' + $group + '].is-active').each(function () {
                            var $sender = $(this);

                            $($sender.data('target')).removeClass('is-visible');
                            $sender.removeClass('is-active');
                        });
                    }
                });
            });

            if ($('.mao-blue-radio').length > 0) {
                $('.checkout-payment-block__input').on('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                });

                $('.mao-blue-radio .mao-select').on('click', function (e) {
                    e.stopPropagation();
                });

                $('.mao-blue-radio').on('click', function (e) {
                    if (!$(this).is('.active')) {
                        $('.mao-blue-radio').removeClass('active');
                        $(this).addClass('active');

                        let _radio = $(this).find('input[type=radio]');
                        var _state = $(_radio).prop('checked');
                        $(_radio).prop('checked', !_state);
                    }
                });
            }

            if ($('.mao-ellipsis').length) {
                $('.mao-ellipsis').dotdotdot({
                    watch: 'window',
                });
            }

            // Swipers;
            $(window).on('resize', function (e) {
                if ($('.swiper-recentlyViewed').length) {
                    var _recentlyViewed = new Swiper('.swiper-recentlyViewed .swiper-container', {
                        pagination: '.swiper-pagination-recentlyViewed',
                        slidesPerView: MAO_MAIN.getAmountSlides(),
                        paginationClickable: true,
                        preventClicks: false,
                        preventClicksPropagation: false,
                    });

                    _recentlyViewed.params.slidesPerView = MAO_MAIN.getAmountSlides();
                    _recentlyViewed.update();
                }

                if ($(window).width() <= 767) {
                    $('#mainWrapper').removeClass('has-filters');
                } else {
                    $('#mainWrapper').addClass('has-filters');
                }
            });
        },
        toggle_catalog_collapse: function () {
            if ($('#xs-check').is(':visible')) {
                $('.js_main_collapse.collapse').collapse('hide');
            } else {
                $('.js_main_collapse.collapse').collapse('show');
            }
        },
        showOverlay: function () {
            $('.mao-overlay').css('display', 'block');
        },
        hideOverlay: function () {
            $('.mao-overlay').css('display', 'none');
        },
        closeCookieBar: function () {
            $('.cookie-notification button').on('click', function (e) {
                $.post($('.cookie-notification form').attr('action'), $('.cookie-notification form').serialize(), function (data) {
                    $('.cookie-notification').slideToggle('slow');
                    e.preventDefault;
                });

                return false;
            });
        },
        initTabs: function () {
            $('body').on('click', '.mao-tabs-head li', function (e) {
                e.preventDefault();
                var _t = $(this).closest('.mao-tabs');
                var _li = $(_t).find('li');
                var _b = $(_t).find('.mao-tabs-body');
                var _i = $(this).index();

                $(_li).removeClass('active');
                $(_b).removeClass('active');
                $(this).addClass('active');
                $($(_b).get(_i)).addClass('active');
            });
        },
        closeAllHeaderFlyouts: function () {
            $('html').removeClass('has-menu has-login has-minicart has-suggest has-whishlist');
            $('.c-main-nav-menu-small').removeClass('is-active');
        },
        initCarComponent: function () {
            var brand_select = $('.mao-brand-search');
            var model_group_select = $('.mao-model-group-search');
            var model_select = $('.mao-model-search');
            var type_select = $('.mao-type-search');
            //init on page load
            if (brand_select.val() != 0) {
                if (typeof model_group_select.val() !== 'undefined') {
                    model_group_select.prop('disabled', false);
                    model_group_select.parent().find('.c-input-group-car-addon').removeClass('is-disabled');
                } else {
                    model_select.prop('disabled', false);
                    model_select.parent().find('.c-input-group-car-addon').removeClass('is-disabled');
                }
            }
            if (model_group_select.val() != 0) {
                model_select.prop('disabled', false);
                model_select.parent().find('.c-input-group-car-addon').removeClass('is-disabled');
            }
            if (model_select.val() != 0) {
                $('.mao-type-search').prop('disabled', false);
                type_select.parent().find('.c-input-group-car-addon').removeClass('is-disabled');
            }

            brand_select.on('change', function (e) {
                MAO_CAR.carModelSearch($(this).serialize());
                MAO_CAR.carModelGroupSearch($(this).serialize());

                if (typeof model_group_select.val() !== 'undefined') {
                    model_group_select.prop('disabled', false);
                    model_group_select.parent().find('.c-input-group-car-addon').removeClass('is-disabled');
                } else {
                    model_select.prop('disabled', false);
                    model_select.parent().find('.c-input-group-car-addon').removeClass('is-disabled');
                }

                type_select.prop('disabled', true);
                type_select.parent().find('.c-input-group-car-addon').addClass('is-disabled');
            });

            model_group_select.on('change', function (e) {
                MAO_CAR.carModelGroupSearch($(this).serialize());
                model_select.prop('disabled', false);
                model_select.parent().find('.c-input-group-car-addon').removeClass('is-disabled');
            });

            model_select.on('change', function (e) {
                MAO_CAR.carTypeSearch($(this).serialize());
                type_select.prop('disabled', false);
                type_select.parent().find('.c-input-group-car-addon').removeClass('is-disabled');
            });

            type_select.on('change', function (e) {
                MAO_CAR.carTypeSet($(this).serialize());
            });

            MAO_CAR.formatSelectCar();
        },
        scrollToElement: function (el, offset) {
            $('html, body').animate(
                {
                    scrollTop: $(el).offset().top + offset,
                },
                1000
            );
        },
    };
})();
