/**
 * MAO Lister Page Module
 */

export var MAO_LISTER = (function () {
    return {
        init: function () {
            $('.js__toggleFilters')
                .off()
                .on('click', function (e) {
                    e.preventDefault();
                    $('.cms-links').show();
                    $('.facets').show();
                    $('#mainWrapper').toggleClass('has-filters');
                });

            $('.js__toggleFiltersAndNoLinks')
                .off()
                .on('click', function (e) {
                    e.preventDefault();
                    $('.cms-links').hide();
                    $('.facets').show();
                    $('#mainWrapper').toggleClass('has-filters');
                });

            $('.js__toggleLinksAndNoFilters')
                .off()
                .on('click', function (e) {
                    e.preventDefault();
                    $('.cms-links').show();
                    $('.facets').hide();
                    $('#mainWrapper').toggleClass('has-filters');
                });

            $('.js__selectSort')
                .off()
                .on('change', function (e) {
                    $(location).attr('href', $(this).children(':selected').val());
                });

            $('.mao-lister-pager-bar__switcher span')
                .off()
                .on('click', function (e) {
                    e.preventDefault();
                    $('.mao-lister-pager-bar__switcher span').removeClass('active');
                    $(this).addClass('active');
                    if ($(this).is('.js__showListView')) {
                        $('.mao-lister-productList').addClass('isList');
                    } else {
                        $('.mao-lister-productList').removeClass('isList');
                    }
                });

            $('.mao-lister-productList')
                .off()
                .on('click', '.mao-button-add', function (evt) {
                    var productFragments = {0: $(this).closest('.mao-product-fragment')};
                    let addPromise = MAO_Shopping_Cart.add(productFragments);

                    // Replace button text with spinner
                    $(evt.target).html('<i class="fa fa-spinner fa-spin"></i> Bestellen');
                    $(evt.target).prop('disabled', true);

                    // Reset button text
                    addPromise.done(function () {
                        $(evt.target).html('<i class="fa fa-check"></i> Bestellen');
                        $(evt.target).prop('disabled', false);
                    });
                });

            $('.mao-addToCartModal__products')
                .off()
                .on('click', '.mao-button', function () {
                    $(this).addClass('added');
                });
        },
    };
})();
