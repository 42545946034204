/**
 * MAO Shopping Cart
 */
var shipmentOptionCall = false;
export var MAO_Shopping_Cart = (function () {
    return {
        getAmountSlides: function () {
            var spv;
            var _w = window.innerWidth;
            if (_w < 768) {
                spv = 1;
            } else if (_w < 1200) {
                spv = 2;
            } else {
                spv = 3;
            }
            return spv;
        },
        init: function () {
            $(window).on('resize', function () {
                if ($('.swiper-bargains').length) {
                    var _bargains = new Swiper('.swiper-bargains .swiper-container', {
                        pagination: '.swiper-pagination-bargains',
                        slidesPerView: MAO_Shopping_Cart.getAmountSlides(),
                        paginationClickable: true,
                        preventClicks: false,
                        preventClicksPropagation: false,
                    });

                    _bargains.params.slidesPerView = MAO_Shopping_Cart.getAmountSlides();
                    _bargains.update();
                }
            });

            $('.cart-table').on('click', '.cart-table-item__remove', function () {
                var item = $(this).closest('.cart-table-item');
                MAO_Shopping_Cart.deleteItem(item.data('local_brand_id'), item.data('catalog_nr'));
            });

            // $('.mao-select__cart-order-quantity').change(function () {
            //     var item = $(this).closest('.cart-table-item');
            //     MAO_Shopping_Cart.updateCartItem(item.data('local_brand_id'), item.data('catalog_nr'), $(this).val());
            // });

            $('.optionQty__js').off('change').on('change', window.onChangeOptionQty);

            $('.tooltip-shipping-costs').on('closed.bs.alert', function () {
                MAO_Shopping_Cart.removeShippingCostsToolTip();
            });

            $('.delivery')
                .off('click')
                .on('click', function (e) {
                    if (shipmentOptionCall != false) {
                        shipmentOptionCall.abort();
                    }
                    if (!$(this).is('.active')) {
                        $('.delivery').removeClass('active');
                        $(this).addClass('active');

                        var _radio = $(this).find('input[type=radio]');
                        var _state = $(_radio).prop('checked');
                        $(_radio).prop('checked', !_state);
                    }

                    $('.cart-summary-container')
                        .css({'font-size': 'xx-large', 'text-align': 'center', 'margin-bottom': '10px'})
                        .html('<i class="fa fa-spinner fa-spin"></i>');
                    shipmentOptionCall = $.post(
                        '/winkelwagen/shipmentOption',
                        {shipmentOption: $(this).find('input[name="shipmentOption"]').val()},
                        function (data) {
                            if (data.status == 'success') {
                                if (data.message && data.message.shoppingCartSummary) {
                                    MAO_Payment.setShoppingCartSummary(data.message.shoppingCartSummary);
                                }
                                MAO_Shopping_Cart.updateCart(data.message);
                                MAO_Shopping_Cart.updateTooltip(data.message);
                            }
                        },
                        'json'
                    );
                });

            $('label.mao-radio')
                .off('click')
                .on('click', function (e) {
                    // Remove all classes
                    $('label.mao-radio').each(function (i, e) {
                        $(e).removeClass('shipping_active');
                    });

                    // Add active class if checked radio
                    if ($(this).find('input[type=radio]:checked')) {
                        $(this).addClass('shipping_active');
                    }
                });

            $('#timeframe')
                .off('change')
                .on('change', function (e) {
                    $.post(
                        '/winkelwagen/timeframe',
                        {timeFrame: $(this).val()},
                        function (data) {
                            if (data.status == 'success') {
                                MAO_Shopping_Cart.updateCart(data.message);
                                MAO_Shopping_Cart.updateTooltip(data.message);
                            }
                        },
                        'json'
                    );
                });
        },
        removeShippingCostsToolTip: function () {
            $.ajax({
                type: 'POST',
                url: route.shoppingcart.removeShippingCostsToolTip,
                data: {}, //dataString,
                dataType: 'json',

                // show http success message (200)
                success: function (data) {},
            });
        },
        add: function (productFragments) {
            var item = {};
            var items = {};
            $.each(productFragments, function (key, productFragment) {
                var $this = $(productFragment);
                var product = $this.find('div#product');
                var orderQuantity = +$this.find('.mao-select').val();
                // when not given we want default value
                if (isNaN(orderQuantity)) {
                    orderQuantity = 1;
                }

                item = {
                    localBrandId: $(product).data('local_brand_id'),
                    catalogNr: $(product).data('catalog_nr'),
                    href: $(product).data('href'),
                    orderQuantity: orderQuantity,
                };
                items[key] = item;
            });
            return $.ajax({
                type: 'POST',
                url: route.shoppingcart.store,
                data: {items: items},
                dataType: 'json',

                // show http success message (200)
                success: function (data) {
                    if (data.status == 'success') {
                        MAO_Shopping_Cart.updateMiniCart(data.message);
                        MAO_Shopping_Cart.updateAdd(data.message);
                        MAO_Shopping_Cart.updateTooltip(data.message);
                        $('#maoAddToCart').modal('toggle');
                    }
                },
            });
        },

        addSingleProduct: function (product) {
            return $.ajax({
                type: 'POST',
                url: route.shoppingcart.store,
                data: {items: [product]},
                dataType: 'json',

                // show http success message (200)
                success: function (data) {
                    if (data.status == 'success') {
                        MAO_Shopping_Cart.updateMiniCart(data.message);
                        MAO_Shopping_Cart.updateAdd(data.message);
                        MAO_Shopping_Cart.updateTooltip(data.message);
                        $('#maoAddToCart').modal('toggle');
                    }
                },
            });
        },

        deleteItem: function (localBrandId, catalogNr) {
            $.ajax({
                type: 'POST',
                url: route.shoppingcart.destroy,
                data: {
                    localBrandId: localBrandId,
                    catalogNr: catalogNr,
                }, //dataString,
                dataType: 'json',

                // show http success message (200)
                success: function (data) {
                    if (data.status == 'success') {
                        MAO_Shopping_Cart.updateCart(data.message);
                        MAO_Shopping_Cart.updateTooltip(data.message);
                    }
                },
            });
        },
        updateCartItem: function (localBrandId, catalogNr, orderQuantity) {
            $.ajax({
                type: 'POST',
                url: route.shoppingcart.updatecartitem,
                data: {
                    localBrandId: localBrandId,
                    catalogNr: catalogNr,
                    orderQuantity: orderQuantity,
                }, //dataString,
                dataType: 'json',

                // show http success message (200)
                success: function (data) {
                    if (data.status == 'success') {
                        MAO_Shopping_Cart.updateCart(data.message);
                        MAO_Shopping_Cart.updateTooltip(data.message);
                    }
                },
            });
        },
        updateMiniCart: function (shoppingCart) {
            $('#miniChart').html(shoppingCart['miniChart']);
            MAO_HEADER.init();
        },
        updateTooltip: function (toolTip) {
            $('.tooltip-bar').html(toolTip['tooltip']);
        },
        updateAdd: function (shoppingCart) {
            $('#maoAddToCart').html(shoppingCart['productAdd']);
            MAO_Shopping_Cart.init();
        },
        updateCart: function (shoppingCart) {
            $('#shoppingCartContainer').html(shoppingCart['shoppingCartContainer']);
            $('#shoppingCartSummary').html(shoppingCart['shoppingCartSummary']);
            MAO_Shopping_Cart.updateMiniCart(shoppingCart);
            MAO_Shopping_Cart.init();
        },

        showConfirmation: function () {
            $('.discountSuccess').removeClass('hide');
        },

        hideConfirmation: function () {
            $('.discountSuccess').addClass('hide');
        },

        showError: function () {
            $('.discountError').removeClass('hide');
            MAO_Shopping_Cart.hideConfirmation();
        },

        hideError: function () {
            $('.discountError').addClass('hide');
        },
    };
})();
