export var MAO_TOGGLE_MORE_LESS = (function () {
    return {
        init: function () {
            $('#toggleheight')
                .first()
                .each(function (i, e) {
                    var toggleHeight = $(e);

                    if (toggleHeight.data('initialized')) {
                        return;
                    }

                    toggleHeight.data('initialized', true);

                    var minimizedHeight = 90;
                    var h = e.scrollHeight;
                    var fromSelector = toggleHeight.data('read-more-from');

                    if (fromSelector) {
                        toggleHeight
                            .find(fromSelector)
                            .first()
                            .each(function (i, till) {
                                minimizedHeight = $(till).position().top + $(till).height();
                                toggleHeight.height(minimizedHeight);

                                if (till == toggleHeight.find(':last').get(0)) {
                                    $('#toggleheightMoreLess').hide();
                                }
                            });
                    }

                    if (minimizedHeight >= h) {
                        $('#toggleheightMoreLess').hide();
                    }

                    $('#toggleheightMoreLess').click(function () {
                        var h = toggleHeight[0].scrollHeight;

                        if (toggleHeight.hasClass('minimized')) {
                            toggleHeight.animate({height: h}, 200).removeClass('minimized');
                        } else {
                            toggleHeight.animate({height: minimizedHeight}, 200).addClass('minimized');
                        }
                    });
                });
        },
    };
})();
