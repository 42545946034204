/**
 * MAO Header Module
 */

var ajaxRequest = null;
export var MAO_HEADER = (function () {
    return {
        init: function () {
            // login for desktop
            var self = this;
            $('.js__login-show').on('click', function (e) {
                e.preventDefault();
                self.showLogin();
            });

            $('.js__login-hide').on('click', function (e) {
                e.preventDefault();
                self.hideLogin();
            });

            // show fpassword block in the login popup
            $('.js__show_fpassword_block').on('click', function (e) {
                e.preventDefault();
                self.showFpasswordBlockInTheLoginPopup();
            });

            // show login block in the login popup
            $('.js__show_login_block').on('click', function (e) {
                e.preventDefault();
                self.showLoginBlockInTheLoginPopup();
            });

            // search suggestions
            $('.mao-header-search input[type="text"]').on('keyup', function () {
                if ($(this).val() !== '') {
                    self.showSuggestions($(this).val());
                } else {
                    self.hideSuggestions();
                }
            });

            // flyouts close
            $('.js__flyout-close').on('click', function (e) {
                e.preventDefault();
                //self.closeFlyout(this);
                MAO_MAIN.closeAllHeaderFlyouts();
            });

            // minicart
            $('.js__minicart-show').on('click', function (e) {
                e.preventDefault();
                self.showMiniBasket();
            });

            // whishlist
            $('.js__whishlist-show').on('click', function (e) {
                e.preventDefault();
                self.showWhishlist();
            });
        },

        showLogin: function () {
            MAO_MAIN.closeAllHeaderFlyouts();
            $('.c-main-nav-menu-small').addClass('is-active');
            $('html').addClass('has-login');
        },
        hideLogin: function () {
            $('html').removeClass('has-login');
        },
        showSuggestions: function (searchQuery) {
            if (searchQuery.length > 2) {
                // Abort previous ajax call
                if (ajaxRequest != null) {
                    ajaxRequest.abort();
                }

                // Load results
                ajaxRequest = $.get('/search/' + encodeURIComponent(searchQuery), function (h) {
                    MAO_MAIN.closeAllHeaderFlyouts();
                    $('#searchResults').html(h);
                    $('html').addClass('has-suggest');
                }).fail(function (e) {
                    $('html').removeClass('has-suggest');
                });
            }
        },
        hideSuggestions: function () {
            $('html').removeClass('has-suggest');
        },
        closeFlyout: function (ctx) {
            $('html').removeClass('has-flyout');
            $(ctx).closest('.mao-header-flyout').removeClass('active');
        },
        showMiniBasket: function () {
            MAO_MAIN.closeAllHeaderFlyouts();
            $('html').addClass('has-minicart');
        },
        showWhishlist: function () {
            MAO_MAIN.closeAllHeaderFlyouts();
            $('html').addClass('has-whishlist');
        },
        showLoginBlockInTheLoginPopup: function () {
            $('.mao-login-popup__fpassword-block').removeClass('active');
            $('.mao-login-popup__login-block').addClass('active');
        },
        showFpasswordBlockInTheLoginPopup: function () {
            $('.mao-login-popup__fpassword-block').addClass('active');
            $('.mao-login-popup__login-block').removeClass('active');
        },
    };
})();
