/**
 * MAO Auto Brand Overview Module
 */

export var ONDERDEELMERK_OVERVIEW = (function () {
    return {
        alphabet: [
            'a',
            'b',
            'c',
            'd',
            'e',
            'f',
            'g',
            'h',
            'i',
            'j',
            'k',
            'l',
            'm',
            'n',
            'o',
            'p',
            'q',
            'r',
            's',
            't',
            'u',
            'v',
            'w',
            'x',
            'y',
            'z',
            '0',
        ],

        init: function () {
            this.brandsLoaded();
            this.brandsView = $('.js__mao-tiled-index-list');
        },
        brandsLoaded: function () {
            var self = this;

            $('.mao-keyboard-key:not(.deactive)').on('click', function (e) {
                var val = $(this).text();
                $('.mao-keyboard-key').removeClass('active');
                $(this).addClass('active');
                $('.mao-keyboard .mao-searchInput input').val(val);

                self.filterAndScroll(val);
            });

            $('.mao-keyboard .mao-searchInput input').on('keyup', function (e) {
                var val = $(this).val();
                var maokeyboardkeys = $('.mao-keyboard-key');
                maokeyboardkeys.removeClass('active');
                if (val != '') {
                    $(maokeyboardkeys.get(self.alphabet.indexOf(val[0]))).addClass('active');
                }

                self.filterAndScroll(val);
            });
        },
        filterAndScroll: function (val) {
            var _match = new RegExp('\\b' + val, 'gi');

            $('.mao-tiled-index-list ')
                .children()
                .each(function () {
                    var elem = $(this);
                    if (elem.data('value').match(_match)) {
                        elem.show();
                    } else {
                        elem.hide();
                    }
                });

            MAO_MAIN.scrollToElement($('.mao-tiled-index-list'), -30);
        },
    };
})();
