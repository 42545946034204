/**
 * MAO Vehicle
 */

const carBrandLogo = [
    {id: 1952, logo: '/logo/alfa-romeo-1.png'},
    {id: 1967, logo: '/logo/Auto-Union-1967.png'},
    {id: 1964, logo: '/logo/audi-2.png'},
    {id: 1965, logo: '/logo/Austin-1965.png'},
    {id: 1974, logo: '/logo/Bedford-1974.png'},
    {id: 1984, logo: '/logo/bmw-3.png'},
    {id: 2009, logo: '/logo/chrysler-7.png'},
    {id: 2010, logo: '/logo/citroen-8.png'},
    {id: 2018, logo: '/logo/Daf-2018.png'},
    {id: 2019, logo: '/logo/daihatsu-11.png'},
    {id: 2020, logo: '/logo/Daimler-2020.png'},
    {id: 2027, logo: '/logo/dodge-12.png'},
    {id: 2042, logo: '/logo/fiat-14.png'},
    {id: 2044, logo: '/logo/ford-15.png'},
    {id: 2063, logo: '/logo/Gmc-2063.png'},
    {id: 2075, logo: '/logo/honda-17.png'},
    {id: 2089, logo: '/logo/Innocenti-2089.png'},
    {id: 2095, logo: '/logo/isuzu-20.png'},
    {id: 2097, logo: '/logo/iveco-21.png'},
    {id: 2100, logo: '/logo/jaguar-22.png'},
    {id: 2114, logo: '/logo/lada-25.png'},
    {id: 2117, logo: '/logo/lancia-26.png'},
    {id: 2139, logo: '/logo/Man-2139.png'},
    {id: 2145, logo: '/logo/mazda-29.png'},
    {id: 2150, logo: '/logo/mercedes-30.png'},
    {id: 2153, logo: '/logo/Mg-2153.png'},
    {id: 2161, logo: '/logo/mitsubishi-32.png'},
    {id: 2165, logo: '/logo/Morris-2165.png'},
    {id: 2178, logo: '/logo/nissan-datsun-33.png'},
    {id: 2180, logo: '/logo/Nsu-2180.png'},
    {id: 2184, logo: '/logo/opel-34.png'},
    {id: 2190, logo: '/logo/peugeot-35.png'},
    {id: 2198, logo: '/logo/porsche-37.png'},
    {id: 2210, logo: '/logo/renault-38.png'},
    {id: 2218, logo: '/logo/rover-49.png'},
    {id: 2221, logo: '/logo/saab-39.png'},
    {id: 2228, logo: '/logo/seat-40.png'},
    {id: 2236, logo: '/logo/skoda-41.png'},
    {id: 2247, logo: '/logo/subaru-44.png'},
    {id: 2250, logo: '/logo/suzuki-santana-45.png'},
    {id: 2253, logo: '/logo/Talbot-2253.png'},
    {id: 2263, logo: '/logo/toyota-46.png'},
    {id: 2266, logo: '/logo/Triumph-2266.png'},
    {id: 2273, logo: '/logo/Vauxhall-2273.png'},
    {id: 2282, logo: '/logo/volvo-48.png'},
    {id: 2283, logo: '/logo/volkswagen-47.png'},
    {id: 2301, logo: '/logo/Zastava-2301.png'},
    {id: 1972, logo: '/logo/Barkas-1972.png'},
    {id: 1986, logo: '/logo/Borgward-1986.png'},
    {id: 2008, logo: '/logo/chevrolet-usa-6.png'},
    {id: 2015, logo: '/logo/dacia-9.png'},
    {id: 2054, logo: '/logo/Gaz-2054.png'},
    {id: 2151, logo: '/logo/Mercury-2151.png'},
    {id: 2225, logo: '/logo/Santana-2225.png'},
    {id: 2243, logo: '/logo/ssangyong-43.png'},
    {id: 2245, logo: '/logo/Steyr-2245.png'},
    {id: 2254, logo: '/logo/Tata-2254.png'},
    {id: 2193, logo: '/logo/Piaggio-2193.png'},
    {id: 2084, logo: '/logo/hyundai-18.png'},
    {id: 2108, logo: '/logo/kia-24.png'},
    {id: 2017, logo: '/logo/daewoo-chevrolet-10.png'},
    {id: 2285, logo: '/logo/Wartburg-2285.png'},
    {id: 2265, logo: '/logo/Trabant-2265.png'},
    {id: 1945, logo: '/logo/Ac-1945.png'},
    {id: 2239, logo: '/logo/Solo-2239.png'},
    {id: 2308, logo: '/logo/Zuendapp-2308.png'},
    {id: 2211, logo: '/logo/Renault-Trucks-2211.png'},
    {id: 2041, logo: '/logo/Ferrari-2041.png'},
    {id: 2115, logo: '/logo/Lamborghini-2115.png'},
    {id: 2216, logo: '/logo/Rolls-Royce-2216.png'},
    {id: 2141, logo: '/logo/Maserati-2141.png'},
    {id: 2209, logo: '/logo/Reliant-2209.png'},
    {id: 2197, logo: '/logo/pontiac-36.png'},
    {id: 2049, logo: '/logo/FSO-2049.png'},
    {id: 2048, logo: '/logo/ford-usa-16.png'},
    {id: 2200, logo: '/logo/Proton-2200.png'},
    {id: 1992, logo: '/logo/Bugatti-1992.png'},
    {id: 1968, logo: '/logo/Autobianchi-1968.png'},
    {id: 2131, logo: '/logo/Lotus-2131.png'},
    {id: 2164, logo: '/logo/Morgan-2164.png'},
    {id: 2047, logo: '/logo/Ford-Otosan-2047.png'},
    {id: 1954, logo: '/logo/Alpine-1954.png'},
    {id: 2062, logo: '/logo/Glas-2062.png'},
    {id: 2166, logo: '/logo/Moskvich-2166.png'},
    {id: 1977, logo: '/logo/Bentley-1977.png'},
    {id: 1993, logo: '/logo/buick-4.png'},
    {id: 1995, logo: '/logo/cadillac-5.png'},
    {id: 2034, logo: '/logo/Eagle-2034.png'},
    {id: 2058, logo: '/logo/Geo-2058.png'},
    {id: 2124, logo: '/logo/lexus-28.png'},
    {id: 2148, logo: '/logo/Mega-2148.png'},
    {id: 2195, logo: '/logo/Plymouth-2195.png'},
    {id: 2199, logo: '/logo/Premier-2199.png'},
    {id: 2269, logo: '/logo/Tvr-2269.png'},
    {id: 1953, logo: '/logo/Alpina-1953.png'},
    {id: 1960, logo: '/logo/Asia-Motors-1960.png'},
    {id: 1962, logo: '/logo/Aston-Martin-1962.png'},
    {id: 2102, logo: '/logo/jeep-23.png'},
    {id: 2286, logo: '/logo/Westfield-2286.png'},
    {id: 2297, logo: '/logo/Yamaha-2297.png'},
    {id: 2106, logo: '/logo/Kawasaki-2106.png'},
    {id: 2238, logo: '/logo/smart-42.png'},
    {id: 2302, logo: '/logo/Zaz-2302.png'},
    {id: 2181, logo: '/logo/Oldsmobile-2181.png'},
    {id: 2127, logo: '/logo/Lincoln-2127.png'},
    {id: 2136, logo: '/logo/Mahindra-2136.png'},
    {id: 1958, logo: '/logo/Aro-1958.png'},
    {id: 2121, logo: '/logo/Ldv-2121.png'},
    {id: 1951, logo: '/logo/Aixam-1951.png'},
    {id: 1979, logo: '/logo/Bertone-1979.png'},
    {id: 1982, logo: '/logo/Bitter-1982.png'},
    {id: 1988, logo: '/logo/Bristol-1988.png'},
    {id: 1998, logo: '/logo/Callaway-1998.png'},
    {id: 1999, logo: '/logo/Carbodies-1999.png'},
    {id: 2001, logo: '/logo/Caterham-2001.png'},
    {id: 2006, logo: '/logo/Checker-2006.png'},
    {id: 2021, logo: '/logo/Dallas-2021.png'},
    {id: 2023, logo: '/logo/De-Lorean-2023.png'},
    {id: 2024, logo: '/logo/De-Tomaso-2024.png'},
    {id: 2046, logo: '/logo/Ford-Australia-2046.png'},
    {id: 2226, logo: '/logo/Saturn-2226.png'},
    {id: 2060, logo: '/logo/Ginetta-2060.png'},
    {id: 2072, logo: '/logo/Hindustan-2072.png'},
    {id: 2074, logo: '/logo/Hobbycar-2074.png'},
    {id: 1946, logo: '/logo/Acura-1946.png'},
    {id: 2080, logo: '/logo/Hummer-2080.png'},
    {id: 2086, logo: '/logo/Indigo-2086.png'},
    {id: 2091, logo: '/logo/Irmscher-2091.png'},
    {id: 2092, logo: '/logo/Isdera-2092.png'},
    {id: 2103, logo: '/logo/Jensen-2103.png'},
    {id: 2126, logo: '/logo/Ligier-2126.png'},
    {id: 2140, logo: '/logo/Marcos-2140.png'},
    {id: 2147, logo: '/logo/Mclaren-2147.png'},
    {id: 2152, logo: '/logo/Metrocab-2152.png'},
    {id: 2158, logo: '/logo/Middlebridge-2158.png'},
    {id: 2159, logo: '/logo/Minelli-2159.png'},
    {id: 2160, logo: '/logo/mini-31.png'},
    {id: 2088, logo: '/logo/infiniti-19.png'},
    {id: 2182, logo: '/logo/Oltcit-2182.png'},
    {id: 2186, logo: '/logo/Osca-2186.png'},
    {id: 2188, logo: '/logo/Panoz-2188.png'},
    {id: 2189, logo: '/logo/Panther-2189.png'},
    {id: 2206, logo: '/logo/Ranger-2206.png'},
    {id: 2207, logo: '/logo/Rayton-Fissore-2207.png'},
    {id: 1985, logo: '/logo/Bond-1985.png'},
    {id: 1966, logo: '/logo/Austin-Healey-1966.png'},
    {id: 2214, logo: '/logo/Riley-2214.png'},
    {id: 2290, logo: '/logo/Wolseley-2290.png'},
    {id: 2232, logo: '/logo/Shelby-2232.png'},
    {id: 2235, logo: '/logo/Sipani-2235.png'},
    {id: 2241, logo: '/logo/Spectre-2241.png'},
    {id: 2244, logo: '/logo/Standard-Automobile-2244.png'},
    {id: 2270, logo: '/logo/Uaz-2270.png'},
    {id: 2272, logo: '/logo/Umm-2272.png'},
    {id: 2274, logo: '/logo/Vector-2274.png'},
    {id: 2276, logo: '/logo/Venturi-2276.png'},
    {id: 2288, logo: '/logo/Wiesmann-2288.png'},
    {id: 2300, logo: '/logo/Yulon-2300.png'},
    {id: 2201, logo: '/logo/Puch-2201.png'},
    {id: 2118, logo: '/logo/land-range-rover-27.png'},
    {id: 2280, logo: '/logo/Vm-2280.png'},
    {id: 2144, logo: '/logo/Maybach-2144.png'},
    {id: 1956, logo: '/logo/Amc-1956.png'},
    {id: 1957, logo: '/logo/Aprilia-1957.png'},
    {id: 1976, logo: '/logo/Benelli-1976.png'},
    {id: 1996, logo: '/logo/Cagiva-1996.png'},
    {id: 2119, logo: '/logo/Landwind-(Jmc)-2119.png'},
    {id: 2055, logo: '/logo/Geely-2055.png'},
    {id: 2113, logo: '/logo/Kymco-2113.png'},
    {id: 2248, logo: '/logo/Sunbeam-2248.png'},
    {id: 1987, logo: '/logo/Bremach-1987.png'},
    {id: 2242, logo: '/logo/Spyker-2242.png'},
    {id: 2031, logo: '/logo/Ducati-2031.png'},
    {id: 2111, logo: '/logo/Ktm-2111.png'},
    {id: 2173, logo: '/logo/Mv-Agusta-2173.png'},
    {id: 2071, logo: '/logo/Hillman-2071.png'},
    {id: 2289, logo: '/logo/Willys-2289.png'},
    {id: 2299, logo: '/logo/Yugo-2299.png'},
    {id: 2076, logo: '/logo/Hongqi-2076.png'},
    {id: 2099, logo: '/logo/Jac-2099.png'},
    {id: 2007, logo: '/logo/Chery-2007.png'},
    {id: 2064, logo: '/logo/Gonow-2064.png'},
    {id: 2067, logo: '/logo/Great-Wall-2067.png'},
    {id: 2162, logo: '/logo/Mitsuoka-2162.png'},
    {id: 2000, logo: '/logo/Casalini-2000.png'},
    {id: 2068, logo: '/logo/Grecav-2068.png'},
    {id: 2105, logo: '/logo/Jmc-2105.png'},
    {id: 2194, logo: '/logo/Pininfarina-2194.png'},
    {id: 2132, logo: '/logo/Lti-2132.png'},
    {id: 1973, logo: '/logo/Baw-1973.png'},
    {id: 2090, logo: '/logo/Iran-Khodro-2090.png'},
    {id: 2125, logo: '/logo/Lifan-2125.png'},
    {id: 1994, logo: '/logo/Byd-1994.png'},
    {id: 2264, logo: '/logo/Toyota-(Gac)-2264.png'},
    {id: 2143, logo: '/logo/Maxus-2143.png'},
    {id: 2256, logo: '/logo/Tesla-2256.png'},
    {id: 2255, logo: '/logo/Tazzari-2255.png'},
    {id: 1959, logo: '/logo/Artega-1959.png'},
    {id: 2029, logo: '/logo/Dr-2029.png'},
    {id: 2258, logo: '/logo/Think-2258.png'},
    {id: 2212, logo: '/logo/Reva-2212.png'},
    {id: 2205, logo: '/logo/Ram-2205.png'},
    {id: 2043, logo: '/logo/Fisker-2043.png'},
    {id: 2011, logo: '/logo/Cmc-2011.png'},
    {id: 1970, logo: '/logo/Baic-1970.png'},
    {id: 2154, logo: '/logo/Mg-(Saic)-2154.png'},
    {id: 1944, logo: '/logo/abarth-50.png'},
    {id: 2155, logo: '/logo/Mia-Electric-2155.png'},
    {id: 2246, logo: '/logo/Streetscooter-2246.png'},
    {id: 1991, logo: '/logo/Buell-1991.png'},
    {id: 2016, logo: '/logo/Daelim-2016.png'},
    {id: 2059, logo: '/logo/Gilera-2059.png'},
    {id: 2069, logo: '/logo/Harley-Davidson-2069.png'},
    {id: 2083, logo: '/logo/Hyosung-2083.png'},
    {id: 2138, logo: '/logo/Malaguti-2138.png'},
    {id: 2146, logo: '/logo/Mbk-2146.png'},
    {id: 2167, logo: '/logo/Moto-Guzzi-2167.png'},
    {id: 2174, logo: '/logo/Mz-2174.png'},
    {id: 2222, logo: '/logo/Sachs-2222.png'},
    {id: 2234, logo: '/logo/Simson-2234.png'},
    {id: 2277, logo: '/logo/Vespa-2277.png'},
    {id: 2109, logo: '/logo/Kreidler-2109.png'},
    {id: 2025, logo: '/logo/Derbi-2025.png'},
    {id: 2040, logo: '/logo/Fantic-2040.png'},
    {id: 2096, logo: '/logo/Italjet-2096.png'},
    {id: 2101, logo: '/logo/Jawa-2101.png'},
    {id: 2120, logo: '/logo/Laverda-Motorcycles-2120.png'},
    {id: 2137, logo: '/logo/Maico-2137.png'},
    {id: 2168, logo: '/logo/Moto-Morini-2168.png'},
    {id: 2192, logo: '/logo/Pgo-Motorcycles-2192.png'},
    {id: 2224, logo: '/logo/Sanglas-2224.png'},
    {id: 2262, logo: '/logo/Tornax-2262.png'},
    {id: 2070, logo: '/logo/Hercules-2070.png'},
    {id: 2220, logo: '/logo/Ruf-2220.png'},
    {id: 2187, logo: '/logo/Pagani-2187.png'},
    {id: 2129, logo: '/logo/Lloyd-2129.png'},
    {id: 2157, logo: '/logo/Microcar-2157.png'},
    {id: 2130, logo: '/logo/Lml-2130.png'},
    {id: 2082, logo: '/logo/Husqvarna-2082.png'},
    {id: 2252, logo: '/logo/Sym-2252.png'},
    {id: 2026, logo: '/logo/Dfsk-2026.png'},
    {id: 2081, logo: '/logo/Husaberg-2081.png'},
    {id: 2035, logo: '/logo/Ecm-2035.png'},
    {id: 2053, logo: '/logo/Gasgas-2053.png'},
    {id: 2213, logo: '/logo/Rex-2213.png'},
    {id: 2073, logo: '/logo/Hmracing-2073.png'},
    {id: 1980, logo: '/logo/Beta-1980.png'},
    {id: 2304, logo: '/logo/Zero-2304.png'},
    {id: 2284, logo: '/logo/Wangye-2284.png'},
    {id: 2278, logo: '/logo/Victory-Motorcycles-2278.png'},
    {id: 2085, logo: '/logo/Indian-2085.png'},
    {id: 2169, logo: '/logo/Motobi-2169.png'},
    {id: 1948, logo: '/logo/Adly-1948.png'},
    {id: 1949, logo: '/logo/Aeon-1949.png'},
    {id: 2163, logo: '/logo/Mondial-2163.png'},
    {id: 2177, logo: '/logo/Nipponia-2177.png'},
    {id: 2204, logo: '/logo/Quadro-2204.png'},
    {id: 1981, logo: '/logo/Bimota-1981.png'},
    {id: 2208, logo: '/logo/Regal-Raptor-2208.png'},
    {id: 2275, logo: '/logo/Vento-2275.png'},
    {id: 2219, logo: '/logo/Royal-Enfield-2219.png'},
    {id: 2240, logo: '/logo/Sommer-Motorcycles-2240.png'},
    {id: 2257, logo: '/logo/Tgb-2257.png'},
    {id: 1975, logo: '/logo/Beeline-1975.png'},
    {id: 2056, logo: '/logo/Generic-2056.png'},
    {id: 2215, logo: '/logo/Rivero-2215.png'},
    {id: 2251, logo: '/logo/Swm-2251.png'},
    {id: 1971, logo: '/logo/Baotian-1971.png'},
    {id: 2005, logo: '/logo/Cf-Moto-2005.png'},
    {id: 2261, logo: '/logo/Tomos-2261.png'},
    {id: 2030, logo: '/logo/ds-13.png'},
    {id: 2112, logo: '/logo/Kuba-2112.png'},
    {id: 2057, logo: '/logo/Genesis-2057.png'},
    {id: 2004, logo: '/logo/Cezeta-2004.png'},
    {id: 2077, logo: '/logo/Horex-2077.png'},
    {id: 2142, logo: '/logo/Mash-2142.png'},
    {id: 2170, logo: '/logo/Motowell-2170.png'},
    {id: 2134, logo: '/logo/Luxxon-2134.png'},
    {id: 2107, logo: '/logo/Keeway-2107.png'},
    {id: 2268, logo: '/logo/Turbho-2268.png'},
    {id: 2231, logo: '/logo/Sfm-2231.png'},
    {id: 2110, logo: '/logo/Ksr-Moto-2110.png'},
    {id: 2066, logo: '/logo/Govecs-2066.png'},
    {id: 1989, logo: '/logo/Brixton-1989.png'},
    {id: 2271, logo: '/logo/Um-2271.png'},
    {id: 2303, logo: '/logo/Zenos-Cars-2303.png'},
    {id: 2135, logo: '/logo/Lynk-&-Co-2135.png'},
    {id: 2179, logo: '/logo/Niu-2179.png'},
    {id: 2002, logo: '/logo/Ccm-2002.png'},
    {id: 2149, logo: '/logo/Megelli-2149.png'},
    {id: 2227, logo: '/logo/Scomadi-2227.png'},
    {id: 1961, logo: '/logo/Asiawing-1961.png'},
    {id: 2052, logo: '/logo/Garelli-2052.png'},
    {id: 2028, logo: '/logo/Donkervoort-2028.png'},
    {id: 2191, logo: '/logo/Pgo-2191.png'},
    {id: 2293, logo: '/logo/Xgjao-2293.png'},
    {id: 2098, logo: '/logo/Izh-2098.png'},
    {id: 2217, logo: '/logo/Romanital-2217.png'},
    {id: 2022, logo: '/logo/De-La-Chapelle-2022.png'},
    {id: 2287, logo: '/logo/Wey-2287.png'},
    {id: 2065, logo: '/logo/Goupil-2065.png'},
    {id: 2202, logo: '/logo/Qingqi-2202.png'},
    {id: 2176, logo: '/logo/Nio-2176.png'},
    {id: 2104, logo: '/logo/Jinlun-2104.png'},
    {id: 1978, logo: '/logo/Benzhou-1978.png'},
    {id: 2305, logo: '/logo/Znen-2305.png'},
    {id: 2013, logo: '/logo/Cpi-2013.png'},
    {id: 2196, logo: '/logo/Polestar-2196.png'},
    {id: 2014, logo: '/logo/Cupra-2014.png'},
    {id: 2306, logo: '/logo/Zongshen-2306.png'},
    {id: 1947, logo: '/logo/Adiva-1947.png'},
    {id: 1963, logo: '/logo/Atala-1963.png'},
    {id: 2298, logo: '/logo/Yiben-2298.png'},
    {id: 2038, logo: '/logo/Eton-2038.png'},
    {id: 2079, logo: '/logo/Huatian-2079.png'},
    {id: 2123, logo: '/logo/Lexmoto-2123.png'},
    {id: 2128, logo: '/logo/Lingben-2128.png'},
    {id: 2050, logo: '/logo/Gac-Mobylette-2050.png'},
    {id: 2294, logo: '/logo/Xingyue-2294.png'},
    {id: 2223, logo: '/logo/Sanben-2223.png'},
    {id: 2279, logo: '/logo/Vinfast-2279.png'},
    {id: 2185, logo: '/logo/Ora-2185.png'},
    {id: 1990, logo: '/logo/Bsa-Motorcycles-1990.png'},
    {id: 2116, logo: '/logo/Lambretta-2116.png'},
    {id: 2233, logo: '/logo/Sherco-2233.png'},
    {id: 2295, logo: '/logo/Xpeng-2295.png'},
    {id: 2122, logo: '/logo/Levc-2122.png'},
    {id: 2172, logo: '/logo/Mpm-Motors-2172.png'},
    {id: 2061, logo: '/logo/Giotti-Victoria-2061.png'},
    {id: 2051, logo: '/logo/Galloper-2051.png'},
    {id: 2012, logo: '/logo/Comarth-2012.png'},
    {id: 1950, logo: '/logo/Aiways-1950.png'},
    {id: 2260, logo: '/logo/Togg-2260.png'},
    {id: 2229, logo: '/logo/Seres-2229.png'},
    {id: 2033, logo: '/logo/E.Go-2033.png'},
    {id: 2281, logo: '/logo/Voge-2281.png'},
    {id: 2094, logo: '/logo/Isorivolta-2094.png'},
    {id: 2093, logo: '/logo/Iso-2093.png'},
    {id: 1983, logo: '/logo/Bizzarrini-1983.png'},
    {id: 2003, logo: '/logo/Cenntro-2003.png'},
    {id: 2237, logo: '/logo/Skywell-2237.png'},
    {id: 2045, logo: '/logo/Ford-Africa-2045.png'},
    {id: 2036, logo: '/logo/Elaris-2036.png'},
    {id: 2230, logo: '/logo/Sevic-2230.png'},
    {id: 2183, logo: '/logo/Online-2183.png'},
    {id: 2291, logo: '/logo/Xbus-2291.png'},
    {id: 2267, logo: '/logo/Tropos-2267.png'},
    {id: 1997, logo: '/logo/Cake-1997.png'},
    {id: 2249, logo: '/logo/Super-Soco-2249.png'},
    {id: 2032, logo: '/logo/E.F.O-2032.png'},
    {id: 2307, logo: '/logo/Zontes-2307.png'},
    {id: 2078, logo: '/logo/Horwin-2078.png'},
    {id: 2133, logo: '/logo/Lucid-2133.png'},
    {id: 2156, logo: '/logo/Micro-2156.png'},
    {id: 2171, logo: '/logo/Motron-2171.png'},
    {id: 2087, logo: '/logo/Ineos-2087.png'},
    {id: 2292, logo: '/logo/Xev-2292.png'},
    {id: 2203, logo: '/logo/Qjmotor-2203.png'},
    {id: 2296, logo: '/logo/Yadea-2296.png'},
    {id: 2259, logo: '/logo/Tinbot-2259.png'},
    {id: 1969, logo: '/logo/B-On-1969.png'},
    {id: 1955, logo: '/logo/Alrendo-1955.png'},
    {id: 2037, logo: '/logo/Energica-2037.png'},
    {id: 2039, logo: '/logo/Evum-Motors-2039.png'},
    {id: 2175, logo: '/logo/Nextem-2175.png'},
];

export var MAO_CAR = (function () {
    return {
        init: function () {
            MAO_CAR.initSelects();
            $('.vehicleChangeJq').click(function () {
                $(this).closest('form').submit();
            });

            $('#resolveLicensePlate').on('click', function (event) {
                let btnText = $('#resolveLicensePlate').html();
                $('#resolveLicensePlate').html('<i class="fa fa-spinner fa-spin"></i> ' + btnText);
                $('#resolveLicensePlate').prop('disabled', true);

                $('.mao-vehicle-search').submit();
            });

            $('#searchHorizontalLicensePlate').on('click', function () {
                let btnText = $('#searchHorizontalLicensePlate').html();
                $('#searchHorizontalLicensePlate').html('<i class="fa fa-spinner fa-spin"></i> ' + btnText);
                $('#searchHorizontalLicensePlate').prop('disabled', true);

                $('.mao-vehicle-search').submit();
            });

            $('.mao-plate-search').on('submit', function (event, e) {
                var form = this;

                if (typeof event.typeCode !== 'undefined') {
                    $('input[name=type_code]', form).val(event.typeCode);

                    return;
                }

                var licensePlate = $('input[name=license_plate]', form).val();
                if (licensePlate === '') {
                    return;
                }

                event.preventDefault();

                $.ajax({
                    dataType: 'json',
                    url: '/car/license-plate/' + encodeURI(licensePlate) + '/cars',
                    success: function (data) {
                        if (data.length === 1) {
                            var submit = jQuery.Event('submit');
                            submit.typeCode = data[0].typeCode;
                            $(form).trigger(submit);

                            return;
                        }

                        var modal = $('#maoChooseType');

                        $('.header-text', modal).html(
                            $('<span>')
                                .css('font-weight', 'normal')
                                .append($('<strong>').text(data.length + ' resultaten'))
                                .append($('<span>').text(" gevonden voor '" + licensePlate + "'"))
                        );

                        $('input[name=license_plate]', modal).val(licensePlate);

                        $('.cars', modal).html(
                            $.map(data, function (type) {
                                return $('<div class="radio" />').html(
                                    $('<label class="mao-radio" />')
                                        .append(
                                            $('<input type="radio" name="type_code">')
                                                .val(type.typeCode)
                                                .change(function () {
                                                    $('.continue', modal).attr('disabled', false);
                                                })
                                        )
                                        .append(
                                            $('<span>')
                                                .append(
                                                    $('<span class="car-brand">').css('background-image', 'url(' + type.carBrandLogo + ')')
                                                )
                                                .append($('<span>').text(type.fullModelName))
                                                .append('<br>')
                                                .append($('<span>').text(type.fullTypeName))
                                        )
                                );
                            })
                        );

                        $('.continue', modal).attr('disabled', true);

                        $('#maoChooseType').modal('show');
                    },
                    error: function () {
                        var submit = jQuery.Event('submit');
                        submit.typeCode = 0;

                        $(form).trigger(submit);
                    },
                });
            });

            $('.font-info .fa').click(function () {
                $('#kbaExplanation').modal('show');
            });

            $('#resolveKBA').click(function () {
                let btnText = $('#resolveKBA').html();
                let uri = `/car/kba/${$('#kbaHSN').val()}/${$('#kbaTSN').val()}/cars`;
                let slug = $('#kbaHSN').data('slug') ?? '/autoteile/';

                $('#kbaError').hide();

                $('#resolveKBA').html('<i class="fa fa-spin fa-spinner"/> ' + btnText);
                $('#resolveKBA').prop('disabled', true);

                let req = $.get(uri).then(
                    function (items) {
                        $('#resolveKBA').html(btnText);
                        $('#resolveKBA').prop('disabled', false);

                        if (items.length > 1) {
                            let itemHtml = '<ul class="kbaList">';
                            items.forEach((item) => {
                                itemHtml += `<li>
<img src="${item.carBrandLogo}">
${item.carBrandName} - ${item.fullModelName} - ${item.fullTypeName} <a href="${slug}?typeCode=${item.typeCode}" class="mao-blueLink">Fahrzeug auswählen <i class="fa fa-angle-right"></i></a>
</li>`;
                            });
                            itemHtml += '</ul>';

                            $('#kbaChoose').html(itemHtml);

                            $('#kbaChoice').modal('show');
                        } else {
                            if (items[0]) {
                                document.location.href = `${slug}?typeCode=${items[0].typeCode}`;
                            } else {
                                $('#kbaError').show();
                            }
                        }
                    },
                    () => {
                        $('#resolveKBA').html(btnText);
                        $('#resolveKBA').prop('disabled', false);
                        $('#kbaError').show();
                    }
                );

                return false;
            });

            $('.licensePlateJq')
                .on('keydown', function (e) {
                    let keyCode = e.keyCode;
                    if (e.keyCode === 13) $(this).trigger('input');
                })
                .on('input', function (e) {
                    var value = jQuery(this).val().replace(/\-/g, '');
                    var sd = GetSidecodeLicenseplate(value);
                    var sl = FormatLicenseplate(value, sd);
                    jQuery(this).val(sl).putCursorAtEnd();
                });

            $('a[data-swap]').on('click', function () {
                switch ($(this).data('swap')) {
                    case 'mao-mmt':
                        $('.mao-plate').slideToggle();
                        $('.mao-mmt').slideToggle();
                        break;

                    case 'mao-plate':
                        $('.mao-mmt').slideToggle();
                        $('.mao-plate').slideToggle();
                        break;
                }
                return false;
            });

            function GetSidecodeLicenseplate(Licenseplate) {
                var arrSC = new Array();
                var scUitz = '';
                Licenseplate = Licenseplate.replace(/-/g, '').toUpperCase();

                arrSC[0] = /^[a-zA-Z]{2}[\d]{2}[\d]{2}$/; //   1       XX-99-99
                arrSC[1] = /^[\d]{2}[\d]{2}[a-zA-Z]{2}$/; //   2       99-99-XX
                arrSC[2] = /^[\d]{2}[a-zA-Z]{2}[\d]{2}$/; //   3       99-XX-99
                arrSC[3] = /^[a-zA-Z]{2}[\d]{2}[a-zA-Z]{2}$/; //   4       XX-99-XX
                arrSC[4] = /^[a-zA-Z]{2}[a-zA-Z]{2}[\d]{2}$/; //   5       XX-XX-99
                arrSC[5] = /^[\d]{2}[a-zA-Z]{2}[a-zA-Z]{2}$/; //   6       99-XX-XX
                arrSC[6] = /^[\d]{2}[a-zA-Z]{3}[\d]{1}$/; //   7       99-XXX-9
                arrSC[7] = /^[\d]{1}[a-zA-Z]{3}[\d]{2}$/; //   8       9-XXX-99
                arrSC[8] = /^[a-zA-Z]{2}[\d]{3}[a-zA-Z]{1}$/; //   9       XX-999-X
                arrSC[9] = /^[a-zA-Z]{1}[\d]{3}[a-zA-Z]{2}$/; //   10      X-999-XX
                arrSC[10] = /^[a-zA-Z]{3}[\d]{2}[a-zA-Z]{1}$/; //   11      XXX-99-X

                //except licenseplates for diplomats
                scUitz = '^CD[ABFJNST][0-9]{1,3}$'; //for example: CDB1 of CDJ45

                for (let i = 0; i < arrSC.length; i++) {
                    if (Licenseplate.match(arrSC[i])) {
                        return i + 1;
                    }
                }
                if (Licenseplate.match(scUitz)) {
                    return 'CD';
                }
                return false;
            }

            function FormatLicenseplate(Licenseplate, Sidecode) {
                Licenseplate = Licenseplate.replace(/-/g, '').toUpperCase();

                if (Licenseplate.length === 0) {
                    return '';
                }

                if (Sidecode <= 6) {
                    if (Licenseplate.length <= 2) {
                        return Licenseplate.substr(0, 2);
                    } else if (Licenseplate.length <= 4) {
                        return Licenseplate.substr(0, 2) + '-' + Licenseplate.substr(2, 2);
                    } else {
                        return Licenseplate.substr(0, 2) + '-' + Licenseplate.substr(2, 2) + '-' + Licenseplate.substr(4, 2);
                    }
                }
                if (Sidecode == 7 || Sidecode == 9) {
                    return Licenseplate.substr(0, 2) + '-' + Licenseplate.substr(2, 3) + '-' + Licenseplate.substr(5, 1);
                }
                if (Sidecode == 8 || Sidecode == 10) {
                    return Licenseplate.substr(0, 1) + '-' + Licenseplate.substr(1, 3) + '-' + Licenseplate.substr(4, 2);
                }

                if (Sidecode == 11) {
                    return Licenseplate.substr(0, 3) + '-' + Licenseplate.substr(3, 2) + '-' + Licenseplate.substr(5, 1);
                }
                return Licenseplate;
            }
        },

        initSelects: function () {
            $('.select2-container').remove();
            $('.mao-brand-search')
                .select2({
                    dropdownCssClass: 'dropdownSelect dropdownSelectCar',
                    placeholder: translation.selectBrand,
                    templateResult: MAO_CAR.formatBrand,
                    dropdownPosition: 'below',
                })
                .on('select2:open', function () {
                    $('.select2-dropdown--above').attr('id', 'fixBrand');
                    $('#fixBrand').removeClass('select2-dropdown--above');
                    $('#fix').addClass('select2-dropdown--below');
                });

            $('.mao-model-search')
                .select2({
                    dropdownCssClass: 'dropdownSelect dropdownSelectModel',
                    placeholder: translation.selectModel,
                    templateResult: MAO_CAR.formatModel,
                    dropdownPosition: 'below',
                })
                .on('select2:open', function () {
                    $('.select2-dropdown--above').attr('id', 'fixModel');
                    $('#fixModel').removeClass('select2-dropdown--above');
                    $('#fixModel').addClass('select2-dropdown--below');
                });

            $('.mao-type-search')
                .select2({
                    dropdownCssClass: 'dropdownSelect dropdownSelectType',
                    placeholder: 'Selecteer type',
                    templateResult: MAO_CAR.formatType,
                    templateSelection: MAO_CAR.formatTypeText,
                    dropdownPosition: 'below',
                })
                .on('select2:open', function () {
                    $('.select2-dropdown--above').attr('id', 'fixType');
                    $('#fixType').removeClass('select2-dropdown--above');
                    $('#fixType').addClass('select2-dropdown--below');
                });
        },

        formatTypeText: function (type) {
            return type.text.replaceAll('###', ' ');
        },

        formatType: function (carType) {
            const items = carType.text.split('###');

            if (items.length === 1) {
                return carType.text;
            }

            var content = $('<span><span class="t-name"></span><span class="t-specs"></span><span class="t-period"></span></span>');
            $(content).find('span.t-name').text(items[0]);
            $(content)
                .find('span.t-specs')
                .text(items[1] + ' ' + items[2] + '');
            $(content).find('span.t-period').text(items[3]);

            return content;
        },

        carModelGroupSearch: function (modelGroupUrl) {
            var dataString = modelGroupUrl + '&show=' + $('input#show-type').val();
            var slug;
            if ((slug = $('input[name="slug"]').val())) {
                dataString += '&slug=' + slug;
            }
            var url = '/car/model-group';

            MAO_CAR.carSearch(dataString, url);
        },

        carModelSearch: function (modelUrl) {
            var dataString = modelUrl + '&show=' + $('input#show-type').val();
            var slug;
            if ((slug = $('input[name="slug"]').val())) {
                dataString += '&slug=' + slug;
            }
            var url = '/car/model';

            MAO_CAR.carSearch(dataString, url);
        },
        carTypeSearch: function (typeUrl) {
            var dataString = typeUrl + '&show=' + $('input#show-type').val();
            var slug;
            if ((slug = $('input[name="slug"]').val())) {
                dataString += '&slug=' + slug;
            }
            var url = '/car/type';
            MAO_CAR.carSearch(dataString, url);
        },
        carTypeSet: function (typeUrl) {
            var dataString = typeUrl + '&show=' + $('input#show-type').val();
            var slug;
            if ((slug = $('input[name="slug"]').val())) {
                dataString += '&slug=' + slug;
            }
            $.ajax({
                type: 'POST',
                url: '/car/specifications',
                data: dataString,
                dataType: 'json',

                // show http success message (200)
                success: function (data) {
                    if (data['redirect']) {
                        window.location.replace(data['redirect']);
                    }
                },
            });
        },
        carSearch: function (dataString, url) {
            $.ajax({
                type: 'POST',
                url: url,
                data: dataString,
                dataType: 'json',

                // show http success message (200)
                success: function (data) {
                    if (data.status == 'success') {
                        $('.carSearch').html(data.message['carSearch']);
                        MAO_MAIN.initCarComponent();
                        MAO_CAR.initSelects();
                    }
                },
            });
        },
        formatSelectCar: function () {
            var box_to_select_found = false;
            var select_box_count = 1;

            $('.c-select-car').each(function () {
                var car_addon = $(this).parent().find('.c-input-group-car-addon');

                if ($(this).val() === '') {
                    if (!box_to_select_found) {
                        $(this).addClass('c-select-car--green');
                        car_addon.addClass('c-input-group-car-addon--green');
                        car_addon.parent().addClass('sel-active');
                        box_to_select_found = true;
                    }

                    car_addon.html('<strong>' + select_box_count + '</strong>');
                } else {
                    car_addon.addClass('c-input-group-car-addon--ticked');
                }
                select_box_count++;
            });
        },

        formatBrand: function (brand) {
            const base = 'https://cdn6.mijnautoonderdelen.nl/content-page';
            var logo = carBrandLogo.find(function (item) {
                return item.id == brand.id;
            });

            if (logo) {
                var content = $('<span><img class="sel-brand" /><span></span></span>');
                content.find('span').text(brand.text);
                content.find('img').attr('src', base + logo.logo);

                return content;
            }
            return brand.text;
        },

        formatModel: function (model) {
            //console.log(model);

            var period = '';
            var type = '';
            const items = model.text.split('(');
            const name = items[0].trim();

            if (items.length === 1) {
                return model.text;
            }

            if (items.length === 3) {
                period = model.text.substring(model.text.search(/\([0-9]{4} - .*\)/) + 1).slice(0, -1);
                type = items[1].split(')')[0].trim();
            } else {
                period = model.text.substring(model.text.search(/\([0-9]{4} - .*\)/) + 1).slice(0, -1);
            }

            var content = $('<span><span class="m-name"></span><span class="m-period"></span><span class="m-type"></span></span>');
            content.find('span.m-name').text(name);
            content.find('span.m-period').text(period);
            content.find('span.m-type').text(type);

            return content;
        },
    };
})();
