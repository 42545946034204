/**
 *  * MAO_Auth
 * @type {{init, login}}
 */
export var MAO_Auth = (function () {
    return {
        init: function () {
            var auth = $('.js__authenticate');
            auth.on('click', function (e) {
                e.preventDefault(e);
                MAO_Auth.login();
            });
            auth.on('submit', function (e) {
                e.preventDefault(e);
                MAO_Auth.login();
            });

            var forgot_header = $('.js__forgot_header');
            forgot_header.on('click', function (e) {
                e.preventDefault(e);
                MAO_Auth.forgot('.mao-forgot-form-header', '.mao-notification-forgot-header');
            });
            forgot_header.on('submit', function (e) {
                e.preventDefault(e);
                MAO_Auth.forgot('.mao-forgot-form-header', '.mao-notification-forgot-header');
            });

            var forgot = $('.js__forgot');
            forgot.on('click', function (e) {
                e.preventDefault(e);
                MAO_Auth.forgot('.mao-forgot-form', '.mao-notification-forgot');
            });
            forgot.on('submit', function (e) {
                e.preventDefault(e);
                MAO_Auth.forgot('.mao-forgot-form', '.mao-notification-forgot');
            });
        },

        login: function () {
            $.ajax({
                type: 'POST',
                url: route.auth.login,
                data: $('.mao-login-form').serialize(),
                dataType: 'json',

                // show validation failures (400/422)
                error: function (data) {
                    var messages = data.responseJSON;
                    var errorspan = '<ul>';
                    $.each(messages, function (key, value) {
                        errorspan += '<li><i class="fa fa-exclamation-triangle"></i><span>' + value + '</span></li>';
                    });
                    errorspan += '</ul>';

                    var errNameClass = $('.mao-notification-login');
                    errNameClass.addClass('mao-notification warn');
                    errNameClass.html(errorspan);
                },

                // show http success message (200)
                success: function (data) {
                    if (data['redirect']) {
                        window.location.replace(data['redirect']);
                    }
                },
            });
        },

        forgot: function (formClass, messageName) {
            $.ajax({
                type: 'POST',
                url: route.auth.forgot,
                data: $(formClass).serialize(),
                dataType: 'json',

                // show validation failures (400/422)
                error: function (data) {
                    var messages = data.responseJSON;
                    var errorspan = '<ul>';
                    $.each(messages, function (key, value) {
                        errorspan += '<li><i class="fa fa-exclamation-triangle"></i><span>' + value + '</span></li>';
                    });
                    errorspan += '</ul>';

                    var forgotName = $(messageName);
                    forgotName.addClass('mao-notification warn');
                    forgotName.html(errorspan);
                },

                // show http success message (200)
                success: function (data) {
                    if (data['redirect']) {
                        window.location.replace(data['redirect']);
                    }
                    if (data['messages']) {
                        var messages = data['messages'];
                        if (messages['forgotSend']) {
                            var forgotName = $(messageName);
                            var forgotSpan = '<i><span><p class="ticked">' + messages['forgotSend'] + '</p></span> </i>';
                            forgotName.html(forgotSpan);
                            $('.mao-input').hide();
                        }
                    }
                },
            });
        },
    };
})();
