/**
 * MAO Product Detail Page Module
 */

export var MAO_PDP = (function () {
    return {
        getAmountSlides1: function () {
            var spv;
            var _w = window.innerWidth;
            if (_w < 768) {
                spv = 2;
            } else if (_w < 1024) {
                spv = 3;
            } else {
                spv = 3;
            }
            return spv;
        },
        getAmountSlides: function () {
            var spv;
            var _w = window.innerWidth;
            if (_w < 768) {
                spv = 2;
            } else if (_w < 1024) {
                spv = 4;
            } else {
                spv = 6;
            }
            return spv;
        },

        init: function () {
            var self = this;

            var _otherViewed = new Swiper('.swiper-otherViewed .swiper-container', {
                pagination: '.swiper-pagination-otherViewed',
                slidesPerView: this.getAmountSlides1(),
                paginationClickable: true,
                preventClicks: false,
                preventClicksPropagation: false,
            });

            var _recentlyViewed = new Swiper('.swiper-recentlyViewed .swiper-container', {
                pagination: '.swiper-pagination-recentlyViewed',
                slidesPerView: this.getAmountSlides(),
                paginationClickable: true,
                preventClicks: false,
                preventClicksPropagation: false,
            });

            var _subparts = new Swiper('.swiper-subParts .swiper-container', {
                pagination: '.swiper-pagination-subParts',
                slidesPerView: this.getAmountSlides(),
                paginationClickable: true,
            });

            /*
			$(window).on('resize', function() {
				_otherViewed.params.slidesPerView = self.getAmountSlides1();
				_recentlyViewed.params.slidesPerView = self.getAmountSlides();
				_recentlyViewed.update();
			});
			*/

            $('.js__pdpAcco-trigger').on('click', function (e) {
                var _b = $(this).next('.js__pdpAcco-body');
                if (!$(_b).is('.shown')) {
                    $('.js__pdpAcco-body').removeClass('shown');
                    $(_b).addClass('shown');
                } else {
                    $('.js__pdpAcco-body').removeClass('shown');
                }

                $('.js__pdpAcco-trigger').not(this).removeClass('active');
            });
        },
    };
})();
