/**
 * MAO Homepage Module
 */

export var MAO_HOMEPAGE = (function () {
    var spv;
    var self;
    var tabHeads;

    return {
        init: function () {
            self = this;

            // change height of search component
            tabHeads = $('.mao-home-banner__search .mao-tabs-head > li');
            $(tabHeads[0]).on('click', function () {
                $('.mao-home-banner-wrapper').removeClass('is-vehicle-search');
            });
            $(tabHeads[1]).on('click', function () {
                $('.mao-home-banner-wrapper').addClass('is-vehicle-search');
            });

            // Swipers;
            var _swiperBestDeals = new Swiper('.top5-best-deals .swiper-container', {
                pagination: '.swiper-pagination-top5-best-deals',
                slidesPerView: spv,
                paginationClickable: true,
                preventClicks: false,
                preventClicksPropagation: false,
            });

            $(window).on('resize', function () {
                if ($('.top5-best-deals').length) {
                    _swiperBestDeals.params.slidesPerView = MAO_MAIN.getAmountSlides();
                    _swiperBestDeals.update();
                }
            });

            $(document).ready(function () {
                if ($('.top5-best-deals').length) {
                    _swiperBestDeals.params.slidesPerView = MAO_MAIN.getAmountSlides();
                    _swiperBestDeals.update();
                }
            });

            spv = MAO_MAIN.getAmountSlides();
        },
    };
})();
