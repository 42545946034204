/**
 * MAO USER
 */

var validateFieldRequest = false;

export var MAO_USER = (function () {
    return {
        init: function () {
            $('.validate_field')
                .off('blur')
                .on('blur', null, function () {
                    var fieldvalue = $(this).val();
                    var fieldname = $(this).attr('name');
                    MAO_USER.validatefield(fieldname, fieldvalue);
                });

            $('.update_user_jq').on('click', null, function () {
                MAO_USER.update();
            });
        },

        validatefield: function (fieldname, fieldvalue) {
            var dataString =
                fieldname +
                '=' +
                fieldvalue +
                '&inputFieldName=' +
                fieldname +
                '&' +
                $("[name='orderKind']").serialize() +
                '&' +
                $("[name='deliveryLocation']").serialize() +
                '&' +
                $("[name='country']").serialize() +
                '&' +
                $("[name='deliveryCountry']").serialize() +
                '&' +
                $("[name='old_password']").serialize() +
                '&' +
                $("[name='password']").serialize() +
                '&' +
                $("[name='password_confirmation']").serialize();

            if (validateFieldRequest) {
                validateFieldRequest.abort();
            }

            validateFieldRequest = $.ajax({
                type: 'POST',
                url: route.user.validateinputfield,
                data: dataString,
                dataType: 'json',

                // show form request validation failures (422)
                error: function (data) {
                    try {
                        var messages = data.responseJSON.messages;
                        var errName = $('.notification-naw');
                        if (jQuery.type(messages) === 'object' && Object.keys(messages).length > 0) {
                            //insert (new) errors
                            errName.addClass('mao-notification warn');
                            errName.html(
                                '<i class="fa fa-exclamation-triangle"></i><span>' +
                                    __('Één of meerdere velden is niet (correct) ingevuld. Controleer deze en probeer het nogmaals') +
                                    '.</span>'
                            );

                            $.each(messages, function (key, value) {
                                $("[data-fieldname='" + key + "'] .mao-form--label")
                                    .first()
                                    .addClass('error');
                                var field = $("[data-fieldname='" + key + "']");
                                field
                                    .find('#form-error')
                                    .first()
                                    //.html('<span>' + value + '</span>') // don't show errr at this moment
                                    .addClass('has-error');
                                field.find('.form-group').first().removeClass('has-success').addClass('has-error');

                                field.find('.js_mao-form-accountRadio').first().addClass('has-error');
                                field.find('select.mao-select').first().addClass('has-error');
                            });
                        } else {
                            errName.removeClass('mao-notification warn');
                            errName.empty();
                        }
                    } catch (e) {}
                },

                // show http success message (200)
                success: function (data) {
                    var field = $("[data-fieldname='" + fieldname + "']");
                    $("[data-fieldname='" + fieldname + "'] .mao-form--label")
                        .first()
                        .removeClass('error');
                    field.find('#form-error').first().empty().removeClass('has-error');
                    field.find('.form-group').first().removeClass('has-error');

                    field.find('.js_mao-form-accountRadio').removeClass('has-error');
                    field.find('select.mao-select').removeClass('has-error');

                    //only show valid when filled in
                    field.find('.form-group').first().removeClass('has-success');
                    if (fieldvalue.length != 0) {
                        field.find('.form-group').first().addClass('has-success');
                    }

                    //only remove warning when all input errors are resolved
                    var errors = $('.mao-checkout-form').find('.has-error').first();
                    if (errors.length === 0) {
                        var errName = $('.notification-naw');
                        errName.removeClass('mao-notification warn');
                        errName.empty();
                    }

                    validateFieldRequest = false;
                },
            });
        },
        update: function () {
            var mail = $("[name='mailNewsLetter']").is(':checked') ? 1 : 0;
            var method = $('input[name=paymentMethodId]:checked').val();

            const dataString = $('.mao-user-form').serialize() + '&mailNewsLetter=' + mail + '&paymentMethodId=' + method;
            $.ajax({
                type: 'POST',
                url: '/user/update',
                data: dataString,
                dataType: 'json',

                // show form request validation failures (422)
                error: function (data) {
                    var result = data.responseJSON;
                    if (typeof result['errors'] == 'object' && typeof result['errors']['message'] == 'object') {
                        result = result['errors']['message'];
                    }

                    var errName = $('.notification-naw');

                    //clear old errors
                    var field = $('[data-fieldname]');
                    field.removeClass('error');
                    field.find('.mao-form--label').removeClass('error');
                    field.find('#form-error').empty().removeClass('has-error');
                    field.find('.form-group').removeClass('has-error');
                    field.find('.js_mao-form-accountRadio').removeClass('has-error');
                    field.find('select.mao-select').removeClass('has-error');

                    //insert (new) errors
                    errName.removeClass('mao-notification ticked info').addClass('mao-notification warn');
                    errName.html(
                        '<i class="fa fa-exclamation-triangle"></i><span>' +
                            __('Één of meerdere velden is niet (correct) ingevuld. Controleer deze en probeer het nogmaals') +
                            '.</span>'
                    );

                    $.each(result, function (key, value) {
                        $("[data-fieldname='" + key + "'] .mao-form--label")
                            .first()
                            .addClass('error');
                        $("[data-fieldname='" + key + "']")
                            .find('#form-error')
                            .first()
                            //.html('<span>' + value + '</span>')
                            .addClass('has-error');
                        $("[data-fieldname='" + key + "']")
                            .find('.form-group')
                            .first()
                            .removeClass('has-success')
                            .addClass('has-error');

                        $("[data-fieldname='" + key + "']")
                            .find('.js_mao-form-accountRadio')
                            .first()
                            .addClass('has-error');
                        $("[data-fieldname='" + key + "']")
                            .find('select.mao-select')
                            .first()
                            .addClass('has-error');
                    });
                },

                // show http success message (200)
                success: function (data) {
                    //clear old errors
                    var field = $('[data-fieldname]');
                    field.removeClass('error');
                    field.find('.mao-form--label').removeClass('error');
                    field.find('#form-error').empty().removeClass('has-error');
                    field.find('.form-group').removeClass('has-error');
                    field.find('.js_mao-form-accountRadio').removeClass('has-error');
                    field.find('select.mao-select').removeClass('has-error');

                    var errName = $('.notification-naw');
                    errName.removeClass('mao-notification warn').addClass('mao-notification ticked info');
                    errName.html(data.messages[0]);
                },
            });
        },
    };
})();
