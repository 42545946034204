/**
 * MAO Navigation Module
 */

export var MAO_NAVIGATION = (function () {
    return {
        init: function () {
            $('.mao-main-navigation').on('click', '.mao-main-navigation__flag > div:eq(0)', function (e) {
                $('.flag_toggle').toggle();
            });

            $('.mao-main-navigation').on('click', ' > ul > li > a', function (e) {
                e.preventDefault();
                $('.mao-main-navigation > ul').addClass('level-2');
                var hasClass = $(this).closest('li').hasClass('active');
                $('.mao-main-navigation > ul > li').removeClass('active');
                $('.mao-navigation-desktop-close').removeClass('active');
                if (hasClass == false) {
                    if (innerWidth >= 768) {
                        $('.mao-navigation-desktop-close').addClass('active');
                    }
                    $(this).closest('li').addClass('active');
                }
            });

            $('.mao-main-navigation').on('click', '.js__main-navigation__goback', function (e) {
                e.preventDefault();
                $('.mao-main-navigation > ul').removeClass('level-2');
                setTimeout(function () {
                    $('.mao-main-navigation > ul > li').removeClass('active');
                }, 200);
            });

            // invoke main menu for mobile
            $('.mao-header-navtrigger').on('click', function () {
                if (!$('.c-main-nav-menu-small').is('.is-active')) {
                    MAO_MAIN.closeAllHeaderFlyouts();
                    $('html').addClass('has-menu');
                    $('.c-main-nav-menu-small').addClass('is-active');
                    scrollTo(0, 0);
                } else {
                    $('html').removeClass('has-menu');
                    $('.c-main-nav-menu-small').removeClass('is-active');
                }
            });

            // accordion for mobile on level 2
            $('.mao-main-navigation').on('click', '.mao-main-navigation-level2 dt', function (e) {
                if (!$(this).closest('dl').is('.active')) {
                    $('.mao-main-navigation-level2 dl').removeClass('active');
                    $(this).closest('dl').addClass('active');
                } else {
                    $(this).closest('dl').removeClass('active');
                }
            });

            // menu for desktop
            var menu_timeout;

            $('.mao-main-navigation').on('mouseover', ' > ul > li', function () {
                if (innerWidth >= 768) {
                    clearTimeout(menu_timeout);
                }
            });

            $('.mao-main-navigation').on('mouseout', '.mao-main-navigation-level2', function (e) {
                if (innerWidth >= 768) {
                    menu_timeout = setTimeout(function () {
                        $('.mao-main-navigation > ul > li').removeClass('active');
                        $('.mao-navigation-desktop-close').removeClass('active');
                    }, 500);
                }
            });

            // This needs to be MAIN.showLogin();
            $('.mao-main-navigation').on('click', '.js__mobile_login', function (e) {
                e.preventDefault();
                MAO_MAIN.closeAllHeaderFlyouts();
                MAO_HEADER.showLogin();
            });

            // This needs to be LOGIN.hideLogin();

            $('.js__mobile_hide_login').on('click', function (e) {
                e.preventDefault();
                MAO_MAIN.closeAllHeaderFlyouts();
                $('.mao-header-navtrigger').click();
            });

            $('.mao-navigation-desktop-close').on('click', function (e) {
                e.preventDefault();
                $('.mao-main-navigation > ul > li').removeClass('active');
            });

            // close for ipads

            $('.body').on('touchstart', '.navigation-desktop-close', function () {});
        },
    };
})();
