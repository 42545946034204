/**
 * MAO Checkout
 */

export var MAO_Checkout = (function () {
    return {
        init: function () {
            $('button#checkout_mao_jq')
                .off('click')
                .on('click', null, function (e) {
                    e.preventDefault();
                    MAO_Checkout.checkout('checkout', false);
                });

            $('button#checkout_mao_jq_pickup')
                .off('click')
                .on('click', null, function (e) {
                    e.preventDefault();
                    MAO_Checkout.checkout('checkout', true);
                });

            $('button#register_user_jq')
                .off('click')
                .on('click', null, function (e) {
                    e.preventDefault();
                    MAO_Checkout.checkout('user', false);
                });

            $('.checkout_country_update')
                .off('change')
                .on('change', null, function (e) {
                    e.preventDefault();

                    if ($(this).prop('value') != undefined) {
                        if ($(this).prop('value') == 56) {
                            $('.js_mao-form-address-bus').collapse('show');
                        } else {
                            $('.js_mao-form-address-bus').collapse('hide');
                        }
                    }

                    MAO_Checkout.country_update();
                    var fieldvalue = $(this).val();
                    var fieldname = $(this).attr('name');
                    MAO_USER.validatefield(fieldname, fieldvalue);
                });

            $('.checkout_delivery_country_update')
                .off('change')
                .on('change', function (e) {
                    if ($(this).prop('value') == 56) {
                        $('.js_mao-form-address-delivery-bus').collapse('show');
                    } else {
                        $('.js_mao-form-address-delivery-bus').collapse('hide');
                    }
                });
        },

        checkout: function (userAction, pickup) {
            let dataString = $('.mao-checkout-form').serialize() + '&userAction=' + userAction;
            $.ajax({
                type: 'POST',
                url: route.user.signin,
                data: dataString,
                dataType: 'json',

                // show form request validation failures (422)
                error: function (data) {
                    var messages = data.responseJSON.messages;

                    //clear old errors
                    var field = $('[data-fieldname]');
                    field.removeClass('error');
                    field.find('.mao-form--label').removeClass('error');
                    field.find('#form-error').empty().removeClass('has-error');
                    field.find('.form-group').removeClass('has-error');
                    field.find('.js_mao-form-accountRadio').removeClass('has-error');
                    field.find('select.mao-select').removeClass('has-error');

                    var errName = $('.notification-naw');
                    if (jQuery.type(messages) === 'object' && Object.keys(messages).length > 0) {
                        //insert (new) errors
                        errName.addClass('mao-notification warn');
                        errName.html(
                            '<i class="fa fa-exclamation-triangle"></i><span>' +
                                __('Één of meerdere velden is niet (correct) ingevuld. Controleer deze en probeer het nogmaals') +
                                '.</span>'
                        );

                        $.each(messages, function (key, value) {
                            $("[data-fieldname='" + key + "'] .mao-form--label")
                                .first()
                                .addClass('error');
                            var field = $("[data-fieldname='" + key + "']");
                            field
                                .find('#form-error')
                                .first()
                                .html('<span>' + value + '</span>')
                                .addClass('has-error');
                            field.find('.form-group').first().removeClass('has-success').addClass('has-error');

                            field.find('.js_mao-form-accountRadio').first().addClass('has-error');
                            field.find('select.mao-select').first().addClass('has-error');
                        });
                    } else {
                        errName.removeClass('mao-notification warn');
                        errName.empty();
                    }
                },

                // show http success message (200)
                success: function (data) {
                    var field = $('[data-fieldname]');
                    //clear old errors
                    $('[data-fieldname] .mao-form--label').removeClass('error');
                    field.find('#form-error').empty().removeClass('has-error');
                    field.find('.form-group').removeClass('has-error');

                    field.find('.js_mao-form-accountRadio').removeClass('has-error');
                    field.find('select.mao-select').removeClass('has-error');

                    var errName = $('.notification-naw');
                    errName.removeClass('mao-notification warn');
                    errName.empty();

                    if (pickup) {
                        window.location = '/ophaalpunt/';
                    } else {
                        window.location.replace(route.payment.index);
                    }
                },
            });
        },
        country_update: function () {
            var country = $('select[name="country"]').val().toString();
            var deliveryCountry = $('[name=deliveryCountry]').val().toString();
            var deliveryLocation = $('input:radio[name=deliveryLocation]:checked').val().toString();

            var allowedCountries = ['528', '56'];
            var allowedDeliveryLocation = ['1' /* bovenstaand */, '0' /* anders */];

            var chosenCountry = countryCode;
            if (jQuery.inArray(deliveryLocation, allowedDeliveryLocation) !== -1) {
                if (deliveryLocation === '1') {
                    // "bovenstaand"
                    if (jQuery.inArray(country, allowedCountries) !== -1) {
                        chosenCountry = country;
                    }
                } // "anders"
                else {
                    if (jQuery.inArray(deliveryCountry, allowedCountries) === -1) {
                        chosenCountry = country;
                    } else {
                        chosenCountry = deliveryCountry;
                    }
                }
            }

            $.ajax({
                type: 'POST',
                url: route.checkout.updatedeliverycountry,
                data: {chosenCountry: chosenCountry},
                dataType: 'json',

                // show http success message (200)
                success: function (data) {
                    $('#shoppingCartSummary').html(data.message['shoppingCartSummary']);

                    MAO_Checkout.init();
                },
            });
        },
    };
})();
