/**
 * MAO Overview
 */
export var MAO_Overview = (function () {
    return {
        init: function () {
            try {
                window.store.axSetCart();
            } catch (e) {}

            $('button#order_jq').on('click', null, function (e) {
                e.preventDefault();
                MAO_Overview.order();
            });

            $('button#addPayment_jq').on('click', null, function (e) {
                e.preventDefault();
                MAO_Overview.addPayment();
            });
        },

        showConfirmation: function () {
            $('.discountSuccess').removeClass('hide');
        },

        hideConfirmation: function () {
            $('.discountSuccess').addClass('hide');
        },

        showError: function () {
            $('.discountError').removeClass('hide');
            MAO_Overview.hideConfirmation();
        },

        hideError: function () {
            $('.discountError').addClass('hide');
        },

        order: function () {
            $.ajax({
                type: 'POST',
                url: route.order.create,
                data: null,
                dataType: 'json',

                // show http success message (200)
                success: function (data) {
                    if (data['redirect']) {
                        window.location = data['redirect'];
                    }
                },
                error: function (data) {
                    var result = data.responseJSON;
                    if (result['messages'] && result['messages']['showMessage']) {
                        var show = result['messages']['showMessage'];
                        delete result['messages']['showMessage'];

                        for (var i in result['messages']) {
                            var text = result['messages'][i];
                            var output = '<li>' + text + '</li>';
                        }
                        $('.list').empty().append(output);

                        $(show).removeClass('hide');

                        setTimeout(function () {
                            if (result['redirect']) {
                                window.location = result['redirect'];
                            }
                        }, 5000);
                    }
                },
            });
        },
    };
})();
