/**
 * MAO OrderReturn
 */

export var MAO_OrderReturn = (function () {
    return {
        validateButton: function () {
            var valid = false;
            $('.return_item_select_js').each(function (index, element) {
                if (element.checked) {
                    var item = $(element).closest('.c-return-item');
                    if ($(item).find('input[type=radio]:checked').length == 1) {
                        valid = true;
                    } else {
                        valid = false;
                    }
                }
            });

            if (valid) {
                $('.return_confirm').removeAttr('disabled');
            } else {
                $('.return_confirm').attr('disabled', 'disabled');
            }
        },

        init: function () {
            $('.order_return_js').on('click', null, function () {
                MAO_OrderReturn.returnOrder();
            });

            $('.return_item_select_js').on('change', null, function () {
                MAO_OrderReturn.validateButton();

                $(this).closest('.c-return-item').find('.return_reason').toggle();
                $(this).closest('.c-return-item').find('.return_motivation').toggle();
            });

            $('.js_return_reason').on('change', null, function () {
                MAO_OrderReturn.validateButton();
            });
        },

        returnOrder: function () {
            var items = {};
            var orderId;
            var orderLineId;
            $('.return_item_select_js')
                .filter(':checked')
                .each(function (key, item) {
                    var return_item = $(item).closest('.c-return-item');

                    orderId = +$(return_item).data('order_id');
                    orderLineId = $(return_item).data('order_line_id');

                    item = {
                        id: +$(return_item).data('order_line_id'),
                        returnReason: +$(return_item)
                            .find("input[name='return-reason[" + orderLineId + "]']:checked")
                            .val(),
                        returnMotivation: $(return_item).find('.return_motivation_data').val(),
                    };

                    items[key] = item;
                });

            $.ajax({
                type: 'POST',
                url: route.account.return_order_request,
                data: {
                    orderId: orderId,
                    orderLines: items,
                }, //dataString,
                dataType: 'json',

                // show http success message (200)
                success: function (data) {
                    if (data['redirect']) {
                        window.location = data['redirect'];
                    }
                },
                error: function (data) {
                    var result = data.responseJSON;
                    if (result['messages'] && result['messages']['showMessage']) {
                        var show = result['messages']['showMessage'];
                        delete result['messages']['showMessage'];

                        for (var i in result['messages']) {
                            var text = result['messages'][i];
                            var output = '<li>' + text + '</li>';
                        }
                        $('.list').empty().append(output);

                        $(show).removeClass('hide');
                    }
                },
            });
        },
    };
})();
