import jquery from 'jquery';
import $ from 'jquery';
window.jQuery = window.$ = $;
import {MAO_MAIN} from './modules/main.js';
import {MAO_HEADER} from './modules/header.js';
import {MAO_Shopping_Cart} from './modules/shoppingCart.js';
import {MAO_Auth} from './modules/auth.js';
import {MAO_CAR} from './modules/car.js';
import {MAO_HOMEPAGE} from './modules/homepage.js';
import {MAO_USER} from './modules/user.js';
import {MAO_Account} from './modules/account.js';
import {MAO_Checkout} from './modules/checkout_mao.js';
import {MAO_CustomerService} from './modules/customerservice.js';
import {MAO_Payment} from './modules/payment_mao.js';
import {MAO_Overview} from './modules/overview.js';
import {MAO_PDP} from './modules/pdp.js';
import {MAO_Sort} from './modules/sort.js';
import {ONDERDEELMERK_OVERVIEW} from './modules/tiled_index.js';
import {MAO_Facet_Search} from './modules/facet.js';
import {MAO_LISTER} from './modules/lister.js';
import {MAO_TOGGLE_MORE_LESS} from './modules/toggle_more_less.js';
import {MAO_OrderReturn} from './modules/order_return.js';
import {MAO_NAVIGATION} from './modules/navigation.js';
import {LazyLoad} from './vendor/lazyload.js';
import {IScroll} from './vendor/iscroll.js';
import select2 from 'select2';
import ThreeSixty from '@mladenilic/threesixty.js';
import slick from 'slick-carousel/slick/slick.min.js';
import {Widget360, carousel} from './carousel.js';

window.IScroll = IScroll;
window.LazyLoad = LazyLoad;
window.MAO_MAIN = MAO_MAIN;
window.MAO_NAVIGATION = MAO_NAVIGATION;
window.MAO_HEADER = MAO_HEADER;
window.MAO_CAR = MAO_CAR;
window.MAO_Shopping_Cart = MAO_Shopping_Cart;
window.MAO_Auth = MAO_Auth;
window.MAO_HOMEPAGE = MAO_HOMEPAGE;
window.MAO_USER = MAO_USER;
window.MAO_Account = MAO_Account;
window.MAO_Checkout = MAO_Checkout;
window.MAO_CustomerService = MAO_CustomerService;
window.MAO_Payment = MAO_Payment;
window.MAO_Overview = MAO_Overview;
window.MAO_PDP = MAO_PDP;
window.MAO_Sort = MAO_Sort;
window.ONDERDEELMERK_OVERVIEW = ONDERDEELMERK_OVERVIEW;
window.MAO_Facet_Search = MAO_Facet_Search;
window.MAO_LISTER = MAO_LISTER;
window.MAO_TOGGLE_MORE_LESS = MAO_TOGGLE_MORE_LESS;
window.MAO_OrderReturn = MAO_OrderReturn;
window.select2 = select2();
window.ThreeSixty = ThreeSixty;
window.slick = slick;
window.Widget360 = Widget360;
window.carousel = carousel;
