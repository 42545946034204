/**
 * MAO Facet Search
 */

export var MAO_Facet_Search = (function () {
    return {
        init: function () {
            var self = this;

            $('.facet-item').on('change', function (e) {
                MAO_Facet_Search.createFilterUrl();
            });

            $('.mao-listerFacets').on('change', '.facet-item input', function (e) {
                var facetlevelid, facetname;

                facetname = $(this).next().text();
                facetlevelid = $(this).closest('.filter-block').data('facetlevelid');

                if ($(this).prop('checked') === true) {
                    // add current filter
                    self.addFilter(facetlevelid, facetname);
                } else {
                    // remove current filter
                    self.removeFilter(facetlevelid, facetname);
                }
            });

            if ($('.mao-listerFacets__close').length > 0) {
                $('.mao-listerFacets__close').on('click', function (e) {
                    $('#mainWrapper').removeClass('has-filters');
                });
                $('.js__mobile_filters_submit').on('click', function (e) {
                    $('#mainWrapper').removeClass('has-filters');
                });
                $('.js__mobile_filters_clear').on('click', function (e) {
                    $('.mao-chosenFilters__item').click();
                });
            }

            if ($('.mobile-subcategory-menu').length > 0) {
                $('.mobile-subcategory-menu__title').on('click', function (e) {
                    $('.mobile-subcategory-menu').toggleClass('opened');
                });
            }

            $('.mao-chosenFilters--list').on('click', '.mao-chosenFilters__item', function (e) {
                var block = $('.filter-block[data-facetlevelid="' + $(this).data('facetlevelid') + '"]');
                var elem = $($(block).find('[data-facetname="' + $(this).data('facetname') + '"]'));

                if (elem.hasClass('facetSlider')) {
                    var min = elem.bootstrapSlider('getAttribute', 'min');
                    var max = elem.bootstrapSlider('getAttribute', 'max');
                    $(this).remove();

                    elem.bootstrapSlider('setValue', [min, max], true, true);

                    MAO_Facet_Search.createFilterUrl();

                    return;
                }

                $(elem).find('input').click();
            });

            $('.mao-chosenFilters_clearAll').on('click', function (e) {
                e.preventDefault();

                $('.facetSlider').each(function (i, slider) {
                    slider = $(slider);
                    var min = slider.bootstrapSlider('getAttribute', 'min');
                    var max = slider.bootstrapSlider('getAttribute', 'max');

                    slider.bootstrapSlider('setValue', [min, max], true, true);
                });

                $('.mao-chosenFilters__item').remove();
                $('.mao-chosenFilters').addClass('filtersNotChosen');
                $('input[type="checkbox"]').removeAttr('checked');
                MAO_Facet_Search.createFilterUrl();
            });

            if ($('.facetSlider').length) {
                var bootStrap = $('.facetSlider').bootstrapSlider({
                    range: true,
                });

                bootStrap.bind('slide', function (slideEvt) {
                    $('#filterLow' + bootStrap.data('facet-id')).val(slideEvt.value[0]);
                    $('#filterHigh' + bootStrap.data('facet-id')).val(slideEvt.value[1]);
                });

                bootStrap.bind('slideStop', function (slideEvt) {
                    MAO_Facet_Search.createFilterUrl();
                });

                $('.sliderVal').on('keypress', function (e) {
                    if (e.which === 13) {
                        var facetId = $(this).data('facet-id');
                        var low = parseInt($('#filterLow' + facetId).val());
                        var high = parseInt($('#filterHigh' + facetId).val());

                        if (low >= 0 && high >= 0) {
                            bootStrap.bootstrapSlider('setValue', [low, high], true, true);
                            MAO_Facet_Search.createFilterUrl();
                        }
                    }
                });
            }
        },

        getParameterByName: function (name, url) {
            if (!url) {
                url = window.location.href;
            }
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },

        removeParameterFromUrl: function (param, url) {
            url = decodeURI(url).split('?');
            path = url.length == 1 ? '' : url[1];
            path = path.replace(new RegExp('&?' + param + '\\[\\d*\\]=[\\w,]+', 'g'), '');
            path = path.replace(new RegExp('&?' + param + '=[\\w,]+', 'g'), '');
            path = path.replace(/^&/, '');
            return url[0] + (path.length ? '?' + path : '');
        },

        createFilterUrl: function () {
            var orgParams = {};
            var newParams = {};

            // fetch new params from input
            $.each($('input:checked'), function () {
                var key = $(this).data('facetgroupkey');
                var value = $(this).data('facetkey');

                newParams[key] = newParams[key] || [];
                newParams[key].push(value);
            });

            // fetch all slider values
            $.each($('.facetSlider'), function () {
                var key = $(this).data('facet-key');
                var value = $(this).val();

                newParams[key] = newParams[key] || [];
                newParams[key].push(value);
            });

            $.each($('.facetHidden'), function () {
                var key = $(this).data('facetgroupkey');
                var value = $(this).val();

                newParams[key] = newParams[key] || [];
                newParams[key].push(value);
            });

            var paramsToRemember = ['sort', 'typeCode', 'modelGroup'];
            // fetch original params from url
            var match = window.location.href.match(/[^=&?]+\s*=\s*[^&#]*/g) || [];
            $.each(match, function (keys, string) {
                var spl = string.split('=');
                var key = spl[0].replace('%5B%5D', '').replace('[]', '');

                if (jQuery.inArray(key, paramsToRemember) >= 0) {
                    var value = spl[1];
                    orgParams[key] = orgParams[key] || [];
                    orgParams[key].push(value);
                }
            });

            var params = '';
            if (Object.keys(newParams).length > 0) {
                params = '?';

                $.each(newParams, function (name, array) {
                    $.each(array, function (key, string) {
                        params += name + '[]=' + string + '&';
                    });
                });
            }

            if (Object.keys(orgParams).length > 0) {
                if (params.length == 0) {
                    params = '?';
                }

                $.each(orgParams, function (name, array) {
                    $.each(array, function (key, string) {
                        params += name + '=' + string + '&';
                    });
                });
            }

            // Check if we need to add a search[] parameter
            if (params == '' && window.location.pathname == '/results') {
                if (MAO_Facet_Search.getParameterByName('search[]') != null) {
                    params = '?search[]=' + MAO_Facet_Search.getParameterByName('search[]');
                }
            }

            var pathname = window.location.pathname + params;
            if (
                pathname.substr(pathname.length - 1) == '/' ||
                pathname.substr(pathname.length - 1) == '&' ||
                pathname.substr(pathname.length - 1) == '?'
            ) {
                pathname = pathname.slice(0, -1);
            }

            if (window.history.replaceState) {
                window.history.replaceState({}, {}, pathname);
            }

            MAO_Facet_Search.showSpinner();
            $.get(pathname, {t: $.now()}, function (h) {
                $('#mainContent').html(h);
                $('.cms-links').hide();
                MAO_LISTER.init();
                MAO_Facet_Search.removeSpinner();
                MAO_Facet_Search.init();
                MAO_Shopping_Cart.init();
                MAO_CAR.init();
                MAO_TOGGLE_MORE_LESS.init();
                MAO_MAIN.initCarComponent();
            });
        },

        removeSpinner: function removeSpinner() {
            $('.productSpinner').remove();
        },

        showSpinner: function showSpinner() {
            $('.mao-lister-page-main__products-column').css('opacity', '0.1');
            $('.mao-lister-page-main').prepend('<img src="/images/ring-alt.gif" class="productSpinner">');
        },

        addFilter: function (facetlevelid, facetname) {
            var new_facet_item = $('<em class="mao-chosenFilters__item">' + facetname + '<b>&times;</b></em>');
            $(new_facet_item).attr('data-facetlevelid', facetlevelid);
            $(new_facet_item).attr('data-facetname', facetname);
            $('.mao-chosenFilters--list').append($(new_facet_item));
            $('.mao-chosenFilters').removeClass('filtersNotChosen');
        },
        removeFilter: function (facetlevelid, facetname) {
            var item2Remove = '.mao-chosenFilters__item[data-facetname="' + facetname + '"][data-facetlevelid="' + facetlevelid + '"]';
            $(item2Remove).remove();

            if ($('.mao-chosenFilters em').length == 0) {
                $('.mao-chosenFilters').addClass('filtersNotChosen');
            }
        },
    };
})();
